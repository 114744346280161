import Brightness7Icon from '@mui/icons-material/Brightness7';
import ContrastIcon from '@mui/icons-material/Contrast';
import FilterBAndWIcon from '@mui/icons-material/FilterBAndW';
import FormatColorResetIcon from '@mui/icons-material/FormatColorReset';
import StraightenRoundedIcon from '@mui/icons-material/StraightenRounded';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import { Box, Card, Grid, IconButton, Menu, Slider, SvgIcon, ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import Switch from '@mui/material/Switch';
import { useState } from 'react';
import { ManualMeasurementOptions } from '../../types';
import { ReactComponent as CrossHairsIcon } from "./CrossHairs.svg";
import { ReactComponent as DrawAngleIcon } from "./DrawAngleIcon.svg";
import { ReactComponent as DrawLineIcon } from "./DrawLineIcon.svg";
import { ReactComponent as DrawPolygonIcon } from "./DrawPolygonIcon.svg";



export enum ClickAndDragOptions {
    PAN = "pan",
    MEASURE = "measure",
}


export default function ImageControlFilterCard(
    {
        clickAndDragMode,
        setClickAndDragMode,
        resetTransform,
        setInvertFilter,
        invertFilter,
        contrastFilterValue,
        setContrastFilterValue,
        brightnessFilterValue,
        setBrightnessFilterValue,
        measurementType,
        setActiveManualMeasurement, // TODO: rename to setActiveManualMeasurement
        resetAllFilters,
        hideOrientationGuides
    }:
        {
            clickAndDragMode: ClickAndDragOptions,
            setClickAndDragMode: (newMode: ClickAndDragOptions) => void,
            resetTransform: () => void,
            setInvertFilter: (invertFilter: boolean) => void,
            invertFilter: boolean,
            contrastFilterValue: number,
            setContrastFilterValue: (contrastFilterValue: number) => void,
            brightnessFilterValue: number,
            setBrightnessFilterValue: (brightnessFilterValue: number) => void,
            measurementType: ManualMeasurementOptions,
            setActiveManualMeasurement: (measurementType: ManualMeasurementOptions) => void,
            resetAllFilters: () => void,
            hideOrientationGuides: () => void,
        }
) {
    const [contrastMenu, setContrastMenu] = useState<null | HTMLElement>(null);
    const [brightnessMenu, setBrightnessMenu] = useState<null | HTMLElement>(null);

    const openContrast = Boolean(contrastMenu);
    const openBrightness = Boolean(brightnessMenu);

    const handleContrastClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setContrastMenu(event.currentTarget);
    };
    const handleContrastClose = () => {
        setContrastMenu(null);
    };
    const handleBrightnessClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setBrightnessMenu(event.currentTarget);
    };
    const handleBrightnessClose = () => {
        setBrightnessMenu(null);
    };
    const handleMeasurementTypeChange = (event: React.MouseEvent<HTMLElement>, newMeasurementType: ManualMeasurementOptions | null) => {
        if (newMeasurementType !== null) {
            setActiveManualMeasurement(newMeasurementType as ManualMeasurementOptions);
        }
    }
    const handleMeasurementToggle = () => {
        clickAndDragMode === ClickAndDragOptions.MEASURE ?
            setClickAndDragMode(ClickAndDragOptions.PAN) :
            setClickAndDragMode(ClickAndDragOptions.MEASURE)
    }

    return <>
        <Box sx={{
            zIndex: 10, position: "absolute", ml: 1, mt: 1
        }}>
            {/* TODO: Inline styles everywhere :( */}
            <Card sx={{ opacity: 0.95, width: 40 }}>
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="flex-start"
                >
                    {/* <Tooltip title="Zoom in" placement="right">
                        <IconButton onClick={() => zoomIn()}>
                            <ZoomInIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Zoom out" placement="right">
                        <IconButton onClick={() => zoomOut()}>
                            <ZoomOutIcon />
                        </IconButton>
                    </Tooltip> */}
                    <Tooltip title="Reset zoom" placement="right">
                        <IconButton onClick={() => resetTransform()}>
                            <ZoomOutMapIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Card>
            <Card sx={{ opacity: 0.9, mt: 1, width: 40 }}>
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="flex-start"
                >
                    <Tooltip title="Invert black/white" placement="right">
                        <IconButton onClick={() => setInvertFilter(!invertFilter)}>
                            <FilterBAndWIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Adjust contrast" placement="right">
                        <IconButton onClick={handleContrastClick}>
                            <ContrastIcon />
                        </IconButton>
                    </Tooltip>
                    <Menu
                        anchorEl={contrastMenu}
                        open={openContrast}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        onClose={handleContrastClose}
                        sx={{ opacity: 0.9 }}
                    >
                        <Slider
                            onChange={(e, newValue) => setContrastFilterValue(newValue as number)}
                            sx={{ width: 300, p: 0, ml: 1, mt: 5, mb: 1, mr: 1 }}
                            min={0}
                            max={5.0}
                            value={contrastFilterValue}
                            step={0.05}
                            size="small"
                            valueLabelDisplay="on"
                            marks={[{ value: 1.0 },]}
                        />
                    </Menu>
                    <Tooltip title="Adjust brightness" placement="right">
                        <IconButton onClick={handleBrightnessClick}>
                            <Brightness7Icon />
                        </IconButton>
                    </Tooltip>
                    <Menu
                        anchorEl={brightnessMenu}
                        open={openBrightness}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        onClose={handleBrightnessClose}
                        sx={{ opacity: 0.9 }}
                    >
                        <Slider
                            onChange={(e, newValue) => setBrightnessFilterValue(newValue as number)}
                            sx={{ width: 300, p: 0, ml: 1, mt: 5, mb: 1, mr: 1 }}
                            min={0}
                            max={5.0}
                            value={brightnessFilterValue}
                            step={0.05}
                            size="small"
                            valueLabelDisplay="on"
                            marks={[{ value: 1.0 },]}
                        />
                    </Menu>
                    {/* <Tooltip title="Add or remove sepia filter" placement="right">
                        <IconButton onClick={() => setSepiaFilter(!sepiaFilter)}>
                            <ColorizeIcon sx={{ color: "#d4aa7d" }} />
                        </IconButton>
                    </Tooltip> */}
                    <Tooltip title="Reset all filters" placement="right">
                        <IconButton onClick={resetAllFilters}>
                            <FormatColorResetIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Card>
            <Card sx={{ opacity: 0.9, mt: 1, width: 40 }}>
                <Tooltip title="Show/hide the current slice position on other orientation(s)" placement="right">
                    <IconButton onClick={hideOrientationGuides}>
                        <SvgIcon>
                            <CrossHairsIcon />
                        </SvgIcon>
                    </IconButton>
                </Tooltip>
            </Card>
        </Box >
        <Box sx={{ bottom: 0, position: "absolute", ml: 1, mb: 1, zIndex: 10 }}>
            <Grid container justifyContent="center" alignItems="flex-start">
                <Tooltip title="Toggle measure mode" placement="top"
                    PopperProps={{ modifiers: [{ name: "offset", options: { offset: [50, 0], }, },], }}
                >
                    <Card sx={{ opacity: 0.9 }}>
                        <IconButton disableRipple disableFocusRipple onClick={handleMeasurementToggle}>
                            <StraightenRoundedIcon />
                        </IconButton>
                        <Switch checked={clickAndDragMode === ClickAndDragOptions.MEASURE} size='small' onChange={handleMeasurementToggle} />
                    </Card>
                </Tooltip>
                {
                    clickAndDragMode === ClickAndDragOptions.MEASURE &&
                    <>
                        <Card sx={{ opacity: 0.95, ml: 2 }}>
                            <ToggleButtonGroup
                                value={measurementType}
                                exclusive
                                onChange={handleMeasurementTypeChange}
                                size='small'>
                                <ToggleButton value={ManualMeasurementOptions.LINE} >
                                    <Tooltip title="Measure straight length by clicking twice" placement="top">
                                        <SvgIcon>
                                            <DrawLineIcon />
                                        </SvgIcon>
                                    </Tooltip>
                                </ToggleButton>
                                <ToggleButton value={ManualMeasurementOptions.POLYGON} >
                                    <Tooltip title="Measure polygonal area: click to add points, double click to finish" placement="top">
                                        <SvgIcon>
                                            <DrawPolygonIcon />
                                        </SvgIcon>
                                    </Tooltip>
                                </ToggleButton>
                                <ToggleButton value={ManualMeasurementOptions.ANGLE} >
                                    <Tooltip title="Measure angle by clicking in three locations" placement="top">
                                        <SvgIcon>
                                            <DrawAngleIcon />
                                        </SvgIcon>
                                    </Tooltip>
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Card>
                    </>
                }
            </Grid>
        </Box >
    </>
}
