import { useAuth0 } from '@auth0/auth0-react';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import LoopIcon from '@mui/icons-material/Loop';
import { Box, CircularProgress, IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ToggleButton, ToggleButtonGroup } from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { UserAccessState } from '../../types';
import { calculateDateDifference, formatIsoDateTime } from '../../utils';
import { ApiEndpoints, axiosConfigBase } from '../../utils/apiUtils';
import { overlayColorScheme } from '../../utils/colorScheme';
import { parentHeaderSx, parentRowSx, tableCellSx } from '../../utils/tableFormats';
import OperatorPageChildTable from './ChildTable';
import DownloadParentTable from './DownloadParentTable';
import {
  LogEntry, ParentTableData, QueueTypes, Queues, ScanStatusOptions,
  VolumeQueueRaw, externalOperatorParentTableRenamer, reduceScanLogs, statusCalculatorFromRawDbLog
} from './utils';


export default function OperatorPage(
  { userAccessState }: { userAccessState: UserAccessState }
) {
  // Add internal vs external operator.
  const { getIdTokenClaims } = useAuth0();
  const [isLoading, setLoadingStatus] = useState(true)
  const [logEntries, setLogEntries] = useState<LogEntry[]>([]);
  const [openRowId, setOpenRowId] = useState(0);
  const [parentTableData, setParentTableData] = useState<ParentTableData[]>([]);
  const [queueRawData, setQueueRawData] = useState<Queues[]>([]);
  const [isLocalTime, setLocalTime] = useState(true); // or UTC
  const isInternal = [UserAccessState.GLIMPSE_INTERNAL_READ, UserAccessState.GLIMPSE_INTERNAL_WRITE].includes(userAccessState);

  useEffect(() => {
    setLoadingStatus(true);
    getLogList();
    // refresh every 20 seconds:
    const intervalId = setInterval(() => { getLogList() }, 20000);
    return () => clearInterval(intervalId);
  }, [isLocalTime]);


  const getLogList = async () => {
    // TODO this function is horrible.
    const token = await getIdTokenClaims().then((claims) => claims ? claims.__raw : null);
    if (!token) return;

    const volumeRawQueue = await axios.get(ApiEndpoints.OPERATOR_QUEUES, axiosConfigBase(token, { queue: QueueTypes.VOLUME_PATHS_READY }));
    // TODO: I'm leaving the jobs queue here commented out for now. I'm not sure yet if/when to fold this into the operator UI.
    // const jobsRawQueue = await axios.get(ApiEndpoints.OPERATOR_QUEUES, axiosConfigBase(token, { queue: QueueTypes.JOBS_READY }));

    const volumeQueueData = volumeRawQueue.data.map((e: VolumeQueueRaw) => ({
      ...e, queue: QueueTypes.VOLUME_PATHS_READY // TODO: enum this.
    })) as Queues[];
    // Jobs queue is basically doing the same thing right now, plus a scan_id. Lots more information to glean from it though.
    // const jobsQueueData = jobsRawQueue.data.map((e: JosQueueRaw) => ({
    //   timestamp: e.timestamp,
    //   scan_id: e.scan_info.scan_id,
    //   volume_name: e.volume_name,
    //   volume_path: e.scan_path,
    //   queue: QueueTypes.JOBS_READY
    // })) as Queues[];

    let combinedQueueData = volumeQueueData.map((e, index) => ({ ...e, id: index * -1 - 1 })) as Queues[];

    setQueueRawData(combinedQueueData);

    const queueDataForTable: ParentTableData[] = combinedQueueData.map((e) => ({
      queueId: e.id,
      scanId: e.scan_id ? e.scan_id : undefined, // Queues don't have scan_id
      status_message: ScanStatusOptions.IN_VOLUME_QUEUE, // now it's just a volumes queue
      serialNumber: e.volume_name,
      batch: "-",
      minCreated: formatIsoDateTime(e.timestamp, isLocalTime),
      maxCreated: formatIsoDateTime(e.timestamp, isLocalTime),
      duration: 0
    })).sort((a: ParentTableData, b: ParentTableData) => (a.queueId ?? -1) < (b.queueId ?? -1) ? -1 : 1);

    const dbLogs = await axios.get(ApiEndpoints.OPERATOR_LOGS, axiosConfigBase(token))
    setLogEntries(dbLogs.data as LogEntry[]);

    const dbLogData: ParentTableData[] = reduceScanLogs(dbLogs.data as LogEntry[]).map((e) => ({
      scanId: e.scanId,
      status_message: statusCalculatorFromRawDbLog(e),
      serialNumber: e.serialNumber,
      batch: e.batchName,
      minCreated: formatIsoDateTime(e.minCreated, isLocalTime),
      maxCreated: formatIsoDateTime(e.maxCreated, isLocalTime),
      duration: calculateDateDifference(e.maxCreated, e.minCreated)
    })
    )
    setParentTableData(queueDataForTable.concat(dbLogData))
    setLoadingStatus(false);
  }


  const parentTableHeaders = (isInternal: boolean) => {
    if (isInternal) {
      return [
        { key: 'scanId', label: 'Scan ID' },
        { key: 'status_message', label: 'Status' },
        { key: 'serialNumber', label: 'Serial Number' },
        { key: 'batch', label: 'Batch' },
        { key: 'minCreated', label: 'First Log' },
        { key: 'duration', label: 'Duration (minutes)' }
      ] as { key: keyof typeof parentTableData[0], label: string }[]
    } else {
      // What we want to show external users.
      return [
        { key: 'scanId', label: 'Scan ID' },
        { key: 'status_message', label: 'Status' },
        { key: 'serialNumber', label: 'Serial Number' },
        { key: 'minCreated', label: 'First Log' },
        { key: 'duration', label: 'Duration (minutes)' }
      ] as { key: keyof typeof parentTableData[0], label: string }[]
    }
  }


  const parentTableCell = (row: ParentTableData, key: keyof typeof parentTableData[0], isInternalOperator: boolean) => {
    // TODO: if isInternal, we can sanitize this more?
    if (key === "scanId" && (row[key] ?? -1) < 0) return "-"; // scan_id less than 0 is a queue right now. TODO: fix
    if (key === "status_message") {
      let icon = <></>;
      const message = isInternalOperator ? row[key] : externalOperatorParentTableRenamer(row[key] as ScanStatusOptions);
      switch (row.status_message) {
        case ScanStatusOptions.IN_VOLUME_QUEUE:
        case ScanStatusOptions.IN_JOBS_QUEUE:
          icon = <HourglassEmptyIcon sx={{ color: overlayColorScheme.orange }} />
          break
        case ScanStatusOptions.POST_PROCESSING_STARTED:
          icon = <LoopIcon sx={{ color: overlayColorScheme.blue }} />
          break
        case ScanStatusOptions.POST_PROCESSING_COMPLETED:
          icon = <CheckCircleIcon sx={{ color: overlayColorScheme.green }} />
          break
        case ScanStatusOptions.ERROR_IN_PROCESSING:
          icon = <ErrorIcon sx={{ color: overlayColorScheme.red }} />
          break
        default: <></>
      }
      return <>
        <Stack direction={'row'} alignItems="center" spacing={1}>
          {icon}
          <Typography variant={'body2'}>
            {message}
          </Typography>
        </Stack>
      </>
    }
    return row[key]
  }

  const parentTable = () => {
    return <TableContainer >
      <Table size="small">
        <TableHead>
          <TableRow sx={parentHeaderSx}>
            <TableCell sx={tableCellSx}></TableCell>
            {parentTableHeaders(isInternal).map(header => (
              <TableCell key={header.label} sx={tableCellSx}>
                {header.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {parentTableData.length === 0 ? (
            <TableRow>
              <TableCell colSpan={parentTableHeaders(isInternal).length}>No data</TableCell>
            </TableRow>
          ) : (
            parentTableData.map((row, index) => (
              <React.Fragment key={index}>
                <TableRow key={index} sx={parentRowSx}>
                  <TableCell sx={tableCellSx}>
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => {
                        if (openRowId === index) setOpenRowId(-1)
                        else setOpenRowId(index)
                      }}
                    >
                      {openRowId === index ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                  </TableCell>
                  {parentTableHeaders(isInternal).map(header => (
                    <TableCell key={header.key} sx={tableCellSx}>
                      {parentTableCell(row, header.key, isInternal)}
                    </TableCell>
                  ))}
                </TableRow>
                <OperatorPageChildTable
                  rowId={index}
                  queueRawData={queueRawData}
                  parentTableData={parentTableData}
                  openRowId={openRowId}
                  logEntries={logEntries}
                  isLocalTime={isLocalTime}
                  isInternalOperator={isInternal}
                />
              </React.Fragment>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  }

  const loadingComponent = () => {
    return <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <CircularProgress />
    </Box>
  }

  return (
    <Box sx={{ maxWidth: 1300, margin: "auto" }}>
      <Grid container sx={{ pt: 3, pb: 2 }}>
        <Grid xs={9}>
          <Typography variant="h5" sx={{ flexGrow: 1 }} >
            Log of Scans:
          </Typography>
        </Grid>
        <Grid xs={3} >
          <DownloadParentTable tableData={parentTableData} />
          <ToggleButtonGroup
            size='small'
          >
            <ToggleButton
              value={true}
              selected={isLocalTime}
              onClick={() => setLocalTime(true)}
            >
              Local Time
            </ToggleButton>
            <ToggleButton
              value={false}
              selected={!isLocalTime}
              onClick={() => setLocalTime(false)}
            >
              UTC Time
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
      {
        isLoading ? loadingComponent() :
          parentTable()
      }
    </Box >

  );
}
