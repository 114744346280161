import { Box, Fade } from '@mui/material';
import 'katex/dist/katex.min.css'; // `rehype-katex` does not import the CSS for you
import { useEffect, useState } from 'react';
import Markdown from 'react-markdown';
import rehypeKatex from 'rehype-katex';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import './styles.css';


export default function FaqPage() {
  const [faqText, setFaqText] = useState('')
  useEffect(() => {
    fetch("faq_content.md").then(res => res.text()).then(text => setFaqText(text))
  })
  return (
    <Fade in={faqText !== ''}>
      <Box sx={{ pt: "8px", maxWidth: "800px", margin: "auto", textAlign: "left" }
      }>
        <Markdown remarkPlugins={[remarkMath, remarkGfm]} rehypePlugins={[rehypeKatex]} className={"markdown"}>
          {faqText}
        </Markdown>
      </Box >
    </Fade>

  );
}
