import { CardContent, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';

export default function SomethingWentWrongPage() {
    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
        >
            <CardContent>
                <Typography variant="h5" component="div" color='#FFF' sx={{ flexGrow: 1, mt: 8 }}>
                    Something went wrong.
                </Typography>
                <Typography variant="body1" color='#FFF' sx={{ flexGrow: 1, mt: 2 }}>
                    Try waiting a few seconds and reloading, and please let us know if this keeps happening.
                </Typography>
            </CardContent>
        </Grid>
    );
}
