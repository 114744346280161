import Hotjar from '@hotjar/browser';

const hotjarId = 3717492;
const hotjarVersion = 6;

export function doesNotContainAny(inputString: string, stringsToCheck: string[]): boolean {
    for (let i = 0; i < stringsToCheck.length; i++) {
        if (inputString.includes(stringsToCheck[i])) {
            return false;
        }
    }
    return true;
}

export const hotjarInit = (userEmail: string | undefined) => {
    if (userEmail
        // TODO: could use the internal glimpse status, but also mock users are a thing...
        && doesNotContainAny(userEmail, ["@glimp.se", "@glimpse.engineering", "mock@a.com", "mock@b.com", "mock@c.com"])
        && process.env.REACT_APP_API_BASEURL === "https://app.glimp.se"
    ) {
        Hotjar.init(hotjarId, hotjarVersion)
    }
}
