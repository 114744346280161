import useUrlState from '@ahooksjs/use-url-state';
import { Card, CardContent, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import NotFoundPage from '../NotFoundPage';
import { TermsOfServiceVersions } from './termsLibrary';



export default function TosViewer() {
    const [urlState] = useUrlState()
    const [text, setText] = useState("")
    const [termsMd, setTermsMd] = useState('')
    useEffect(() => {
        if (urlState.tos && Object.values(TermsOfServiceVersions).includes(urlState.tos)) {
            try {
                fetch("/terms_of_service_versions/terms_v_" + urlState.tos + ".md").then(res => res.text()).then(text => setTermsMd(text))

            }
            catch (e) { setText("Something went wrong") }
        }
        else { setText("notFound") }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    if (text === "notFound") return (
        <NotFoundPage />
    )
    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
        >
            <Card sx={{ m: 5, width: 1000 }}>
                <CardContent>
                    <Card variant='outlined'>
                        <CardContent>
                            <Markdown remarkPlugins={[remarkGfm]} rehypePlugins={[]} className={"markdown"}>
                                {termsMd}
                            </Markdown>
                        </CardContent>
                    </Card>
                </CardContent>
            </Card>
        </Grid >
    );
}
