import { Fragment } from "react";

export interface EllipseAndLinesSvgs {
    id: number;
    coordinates: number[];
    color: string;
}
export default function MeasurementLayerEllipseAndTwoLinesSvgs(
    {
        ellipsesAndLines,
        strokeWidth
    }: {
        ellipsesAndLines: EllipseAndLinesSvgs[],
        strokeWidth: string | number,
    }
) {
    const ellipseRotation = (e: number[]) => {
        return "rotate(" + e[4] + " " + (e[0]) + " " + (e[1]) + ")"
    }
    return <>
        {ellipsesAndLines.map((e, index) => (
            <Fragment key={index}>
                <ellipse
                    cx={e.coordinates[0]}
                    cy={e.coordinates[1]}
                    rx={e.coordinates[2]}
                    ry={e.coordinates[3]}
                    fill="transparent"
                    transform={ellipseRotation(e.coordinates)}
                    stroke={e.color}
                    strokeWidth={Number(strokeWidth) * 2}
                />
                <line
                    x1={e.coordinates[5]}
                    y1={e.coordinates[6]}
                    x2={e.coordinates[7]}
                    y2={e.coordinates[8]}
                    stroke={e.color}
                    strokeWidth={Number(strokeWidth) * 2.5}
                />
                <line
                    x1={e.coordinates[9]}
                    y1={e.coordinates[10]}
                    x2={e.coordinates[11]}
                    y2={e.coordinates[12]}
                    stroke={e.color}
                    strokeWidth={Number(strokeWidth) * 2.5}
                />
            </Fragment>
        ))
        }
    </>
}
