import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import UserProfile from '../components/UserProfile';

export default function UserPage() {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <CardContent>
                <Typography variant="h5" component="div" color='#FFF' sx={{ flexGrow: 1 }}>
                    User Information
                </Typography>
                <UserProfile />
            </CardContent>
        </Box>
    );
}
