import Grid from '@mui/material/Grid';
import SomethingWentWrongPage from "../pages/SomethingWentWrongPage";
import SubscriptionLoadingPage from "../pages/SubscriptionLoadingPage";
import UserPage from '../pages/UserPage';
import InternalPage from '../pages/internal/page';
import MetrologyPage from '../pages/metrology/page';
import OperatorPage from '../pages/operator/page';
import ScansPage from '../pages/scans/page';
import SlicesPage from '../pages/slices/page';
import TosAcceptor from "../pages/terms_of_service/TosAcceptor";
import { MeasurementDimensions, PageOptions, ScanInfo, UserAccessState } from "../types";


export default function PageSelection({
    selectedPage,
    userAccessState,
    scanList,
    isDemoMode,
    availableMetrics
}: {
    selectedPage: PageOptions,
    userAccessState: UserAccessState,
    scanList: ScanInfo[],
    isDemoMode: boolean
    availableMetrics: MeasurementDimensions[]
}) {
    if (userAccessState === UserAccessState.LOADING) return <SubscriptionLoadingPage />;
    if (userAccessState === UserAccessState.SHOW_TERMS_PAGE) return <TosAcceptor />
    if ([
        UserAccessState.SIGNUP_COMPLETE,
        UserAccessState.SIGNUP_INCOMPLETE,
        UserAccessState.GLIMPSE_INTERNAL_READ,
        UserAccessState.GLIMPSE_INTERNAL_WRITE,
        UserAccessState.CUSTOMER_OPERATOR,
    ]
        .includes(userAccessState)) {
        if (selectedPage === PageOptions.USER) return <UserPage />;
        if (selectedPage === PageOptions.OPERATOR) return <OperatorPage userAccessState={userAccessState} />;
        if (scanList.length === 0) return <Grid
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                pt: "14pt",
            }}>
            No scans were found on your subscription.
        </Grid>;
        switch (selectedPage) {
            case PageOptions.SCANS:
                return <ScansPage scanList={scanList} isDemoMode={isDemoMode} />;
            case PageOptions.SLICES:
                return <SlicesPage scanList={scanList} isDemoMode={isDemoMode} availableMetrics={availableMetrics} />;
            case PageOptions.METROLOGY:
                return <MetrologyPage scanList={scanList} isDemoMode={isDemoMode} availableMetrics={availableMetrics} />;
        }
        // locking down internal pages incase someone tries to access them directly)
        if ([UserAccessState.GLIMPSE_INTERNAL_READ, UserAccessState.GLIMPSE_INTERNAL_WRITE].includes(userAccessState)
            && [PageOptions.INTERNAL_READ, PageOptions.INTERNAL_WRITE].includes(selectedPage))
            return <InternalPage selectedPage={selectedPage} userAccessState={userAccessState} />;
    }
    return <SomethingWentWrongPage />;
}
