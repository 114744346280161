// Consolidating some table formats

export const borderColor = "#ffffff36"

export const backgroundColor = "#ffffff09"

export const subTableWrapperRowSx = { paddingBottom: 0, paddingTop: 0, border: 0 }

export const parentRowSx = { borderTop: 0.2, borderColor: borderColor, backgroundColor: backgroundColor }

export const parentHeaderSx = { borderTop: 0.2, borderColor: borderColor }

export const childRowSx = { borderTop: 0.2, borderColor: borderColor }

export const tableCellSx = { border: 0 }
