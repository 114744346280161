import { Box, Link, Typography } from '@mui/material';
import CardContent from '@mui/material/CardContent';
export default function EmailNotVerified() {
    const copyEmailToClipboard = () => {
        navigator.clipboard.writeText('support@glimp.se').then(function (x) {
            alert("Copied 'support@glimp.se' to clipboard.");
        });
    }
    return (
        <Box sx={{ pt: "8px", maxWidth: "900px", margin: "auto", textAlign: "center" }}>
            <CardContent>
                <Typography variant="h6" sx={{ flexGrow: 1 }}>
                    Thanks for signing up to the Glimpse Portal! Please verify your email to start viewing scans.<br /><br />
                    Check your mailbox for an email from the Glimpse Portal and click “Verify your account”. Then, refresh this page.
                </Typography>
                <br />
                <Typography variant="body1">
                    Please email <Link component="button" onClick={copyEmailToClipboard}>support@glimp.se</Link> if this is unexpected or you are unable to find the email.
                </Typography>
            </CardContent>
        </Box>
    );
}
