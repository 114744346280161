import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box, Link, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import LoginButton from '../components/LoginButton';
import { openTermsPageNewTab } from '../utils';


export default function LoginPage() {
    const imgSrc = "./App_inscreen_teaser.png";
    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
        >
            <Typography variant="h5" component="div" color='#FFF' sx={{ pt: 3 }}>
                Welcome to the Glimpse Portal<sup style={{ fontSize: 11 }}>TM</sup>
            </Typography>
            <Typography variant="body1" sx={{ pt: 3, pb: 3 }}>
                Sign up for a free account to access the demo.
            </Typography>
            <LoginButton />

            <Typography variant="body1" sx={{ pt: 3 }}>
                By using the demo, you are accepting our
                <Link href={openTermsPageNewTab()} target="_blank" sx={{ ml: 1 }}>
                    terms of use.
                    <OpenInNewIcon style={{ fontSize: 14 }} />
                </Link>
            </Typography>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="top"
                maxWidth={900}
            >
                <img
                    src={imgSrc}
                    alt={"glimpse site teaser image"}
                    style={{ maxWidth: '100%', height: 'auto' }} />
            </Box>
        </Grid>
    );
}
