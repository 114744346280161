import { useEffect, useState } from "react";
import { AnnotationShapeList, AnnotationShapeOptions, CommonSvgShapes, OrientationGuideLines, dimensions } from "../../types";
import { conditionallyApplyPadding } from "../../utils/inspection";
import OrientationGuides from "./OrientationGuides";
import MeasurementLayerAngleSvgs from "./svgs/MeasurementLayerAngleSvgs";
import MeasurementLayerAreaSvgs from "./svgs/MeasurementLayerAreaSvgs";
import MeasurementLayerCircleAndLineSvgs, { CircleAndLineSvgShapes } from "./svgs/MeasurementLayerCircleAndLineSvgs";
import MeasurementLayerEllipseAndTwoLinesSvgs, { EllipseAndLinesSvgs } from "./svgs/MeasurementLayerEllipseAndTwoLines";
import MeasurementLayerLineSvgs from "./svgs/MeasurementLayerLineSvgs";
import MeasurementLayerPointsSvgs from "./svgs/MeasurementLayerPointsSvgs";

export default function AnnotationLayer(
    {
        rest,
        orientationGuides,
        zoomLevel,
        svgRef,
        mouseDown,
        mouseMove,
        doubleClick,
        imageDimensions,
        annotationList,
        imageMeasurementPadding
    }: {
        rest: any, // TODO: Could move this up, and drop state being passed around.
        orientationGuides: OrientationGuideLines[] | null,
        zoomLevel: number,
        svgRef: any, // typing this is a pain, because of legacy react ref types
        mouseDown: (e: React.MouseEvent<SVGSVGElement, MouseEvent>, scaledDimensions: dimensions) => void,
        mouseMove: (e: React.MouseEvent<SVGSVGElement, MouseEvent>, scaledDimensions: dimensions) => void,
        doubleClick: (e: React.MouseEvent<SVGSVGElement, MouseEvent>, scaledDimensions: dimensions) => void,
        imageDimensions: dimensions
        annotationList: AnnotationShapeList[],
        imageMeasurementPadding: { horizontal: number, vertical: number },
    }
) {
    const [svgFormats, setSvgFormats] = useState({ strokeWidth: "1", fontSize: "1" });

    useEffect(() => {
        const strokeWidth = (2.5 / zoomLevel).toString();
        const fontSize = (15 / zoomLevel).toString();
        setSvgFormats({
            strokeWidth: strokeWidth,
            fontSize: fontSize
        });
    }, [zoomLevel, imageDimensions]);

    const svgViewBox = () => {
        return `0 0 ${imageDimensions.width} ${imageDimensions.height}`;
    };

    const isValidCoordinates = (coordinates: unknown): coordinates is [number, number][] => {
        return Array.isArray(coordinates) &&
            coordinates.every(coord =>
                Array.isArray(coord) &&
                coord.length === 2 &&
                typeof coord[0] === 'number' &&
                typeof coord[1] === 'number'
            );
    };

    const applyPaddingToAnnotationList = (annotations: AnnotationShapeList[], imageMeasurementPadding: { horizontal: number, vertical: number }) => {
        return annotations.map((e: AnnotationShapeList, index) => ({
            ...e,
            coordinates: e.coordinates ? conditionallyApplyPadding(e.coordinates, e.shapeType, imageMeasurementPadding) as number[] | [number, number][] : [],
        }));
    };

    const paddedAnnotationList = applyPaddingToAnnotationList(annotationList, { horizontal: imageMeasurementPadding.horizontal, vertical: imageMeasurementPadding.vertical });


    const filterMeasurements = (shapeType: string) => {
        return paddedAnnotationList
            .filter(e => e.shapeType === shapeType)
            .filter(e => isValidCoordinates(e.coordinates)) as CommonSvgShapes[];
    };

    const lineMeasurements = () => filterMeasurements(AnnotationShapeOptions.LINE);
    const areaMeasurements = () => filterMeasurements(AnnotationShapeOptions.POLYGON);
    const angleMeasurements = () => filterMeasurements(AnnotationShapeOptions.ANGLE);
    const pointsMeasurements = () => filterMeasurements(AnnotationShapeOptions.POINTS);

    const circlesAndLines = paddedAnnotationList.filter(e => e.shapeType === AnnotationShapeOptions.CIRCLE_AND_LINE) as any as CircleAndLineSvgShapes[];
    const ellipsesAndLines = paddedAnnotationList.filter(e => e.shapeType === AnnotationShapeOptions.ELLIPSE_AND_LINES) as any as EllipseAndLinesSvgs[];

    return <svg
        ref={svgRef as React.RefObject<SVGSVGElement>}
        onMouseDown={(e) => mouseDown(e, {
            height: rest.instance.contentComponent.children[0].height,
            width: rest.instance.contentComponent.children[0].width,
        })}
        onMouseMove={(e) => mouseMove(e, {
            height: rest.instance.contentComponent.children[0].height,
            width: rest.instance.contentComponent.children[0].width,
        })}
        onDoubleClick={(e) => doubleClick(e, {
            height: rest.instance.contentComponent.children[0].height,
            width: rest.instance.contentComponent.children[0].width,
        })}
        viewBox={svgViewBox()}
        style={{ position: 'absolute' }} >
        <MeasurementLayerLineSvgs
            lines={lineMeasurements() as CommonSvgShapes[]}
            strokeWidth={svgFormats.strokeWidth}
        />
        <MeasurementLayerAreaSvgs
            areas={areaMeasurements() as CommonSvgShapes[]}
            strokeWidth={svgFormats.strokeWidth}
        />
        <MeasurementLayerAngleSvgs
            angles={angleMeasurements()}
            strokeWidth={svgFormats.strokeWidth}
        />
        <MeasurementLayerPointsSvgs
            points={pointsMeasurements()}
            strokeWidth={svgFormats.strokeWidth}
        />
        <MeasurementLayerCircleAndLineSvgs
            circlesAndLines={circlesAndLines}
            strokeWidth={svgFormats.strokeWidth}
        />
        <MeasurementLayerEllipseAndTwoLinesSvgs
            ellipsesAndLines={ellipsesAndLines}
            strokeWidth={svgFormats.strokeWidth}
        />
        <OrientationGuides
            lines={orientationGuides}
            fontSize={svgFormats.fontSize}
            strokeWidth={svgFormats.strokeWidth}
        />
    </svg>;
}
