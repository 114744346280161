import { useAuth0 } from '@auth0/auth0-react';
import { Button, Card, CardContent, Input, Typography } from '@mui/material';
import axios from 'axios';
import { useState } from 'react';
import { PostingApiStatus } from '../../types';
import { ApiEndpoints } from '../../utils/apiUtils';
import LoadingMessage from './LoadingMessage';


export default function ToggleScanFlag(
    { }: {}
) {
    const { getIdTokenClaims } = useAuth0();
    const [scanId, setScanId] = useState(0);
    const [uploadState, setUploadState] = useState(PostingApiStatus.FALSE);

    const handleButtonClick = async () => {
        const token = await getIdTokenClaims().then((claims) => claims ? claims.__raw : null);;
        if (scanId === 0) return;
        if (token === null) return;
        axios({
            method: 'put',
            url: ApiEndpoints.INTERNAL_TOGGLE_SCAN_FLAG,
            headers: { Authorization: "Bearer " + token },
            params: { scan_id: scanId },
            baseURL: process.env.REACT_APP_API_BASEURL
        }).then((response) => {
            setUploadState(PostingApiStatus.SUCCESS)
        }).then(() => {
            setTimeout(() => {
                window.location.reload()
            }, 1000)
        })
            .catch((error) => {
                console.error(error)
                setUploadState(PostingApiStatus.ERROR)
            });
    }

    return <>
        <Card sx={{ m: 1, width: 900 }} elevation={2}>
            <CardContent>
                <Typography variant="body1" >
                    Toggle scan flag by scan_id:
                </Typography>
                <Input
                    type='number'
                    value={scanId}
                    onChange={(e) => setScanId(Number(e.target.value))}
                />
                <Button onClick={handleButtonClick}>Submit</Button>
                <LoadingMessage uploadState={uploadState} />
                <Typography variant="body2" >
                    Note: "toggle" meaning true becomes false, false becomes true. This will error if the scan_id is not found.
                </Typography>
            </CardContent>
        </Card>
    </>
};
