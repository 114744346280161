import { Collapse, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { formatIsoDateTime } from '../../utils';
import { childRowSx, parentRowSx, subTableWrapperRowSx, tableCellSx } from '../../utils/tableFormats';
import { LogEntry, ParentTableData, Queues, ScanEventLogEntries, ScanEventLogStatus, checkForTimeout, externalOperatorEventRenamer, externalOperatorStatusRenamer } from './utils';



export default function OperatorPageChildTable(
    {
        rowId,
        queueRawData,
        parentTableData,
        logEntries,
        openRowId,
        isLocalTime,
        isInternalOperator
    }:
        {
            rowId: number,
            queueRawData: Queues[],
            parentTableData: ParentTableData[],
            logEntries: LogEntry[],
            openRowId: number,
            isLocalTime: boolean,
            isInternalOperator: boolean
        }
) {
    const logSanitizer = (logs: LogEntry[], isInternalOperator: boolean) => {
        if (isInternalOperator) { return logs }

        const allowedEvents = [
            ScanEventLogEntries.SCAN_ID_CREATED,
            ScanEventLogEntries.POST_PROCESSING_STARTED,
            ScanEventLogEntries.POST_PROCESSING_COMPLETED,
        ];
        const filteredLogs = logs.filter((log) => allowedEvents.includes(
            log.event_type as ScanEventLogEntries) ||
            log.status === ScanEventLogStatus.ERROR // if Error continue to rename them
        );

        // check for age of logs
        const lastLog = logs[logs.length - 1];
        const lastLogTime = new Date(lastLog.created);
        const timeoutLengthInSeconds = 300; // 300 seconds = 5 minutes
        const timeout = checkForTimeout(lastLogTime, timeoutLengthInSeconds)

        if (timeout && lastLog.event_type !== ScanEventLogEntries.POST_PROCESSING_COMPLETED && lastLog.status !== ScanEventLogStatus.ERROR) {
            return [...filteredLogs, {
                ...filteredLogs[filteredLogs.length - 1],
                created: timeout,
                event_type: "Timeout Error",
                status: "Check that the network cable of the GlimpseBox is plugged in and the box is powered on.",
            }]
        }
        const renamedLogs = filteredLogs.map((e) => ({
            ...e,
            event_type: externalOperatorEventRenamer(e.event_type as ScanEventLogEntries),
            status: externalOperatorStatusRenamer(e.event_type as ScanEventLogEntries, e.status as ScanEventLogStatus),
        }));

        function hasTimeDifferenceGreaterThanFiveMinutes(logEntries: LogEntry[]): boolean {
            // Convert created property to Date objects if they are strings
            logEntries.forEach(logEntry => {
                if (typeof logEntry.created === 'string') {
                    logEntry.created = new Date(logEntry.created);
                }
            });
            const sortedLogEntries = logEntries.sort((a, b) => (a.created as Date).getTime() - (b.created as Date).getTime());
            for (let i = 1; i < sortedLogEntries.length; i++) {
                const previousLogEntry = sortedLogEntries[i - 1];
                const currentLogEntry = sortedLogEntries[i];
                const timeDifference = ((currentLogEntry.created as Date).getTime() - (previousLogEntry.created as Date).getTime()) / 1000; // time difference in seconds
                if (timeDifference > timeoutLengthInSeconds) { return true }
            }
            return false;
        }

        if (hasTimeDifferenceGreaterThanFiveMinutes(logs))
            return [...renamedLogs, {
                created: renamedLogs[renamedLogs.length - 1].created,
                event_type: "Timeout Warning",
                status: "One of the processing steps took longer than expected, please let us know if this persists.",
            }];
        return renamedLogs;
    }
    const openRow = parentTableData[rowId];
    let fragment = <></>;
    if (openRow.queueId) {
        const queueData = queueRawData.filter((e) => e.id === parentTableData[rowId].queueId);
        fragment = <Table size="small" sx={{ minWidth: 550, maxWidth: 700, }} >
            <TableHead>
                <TableRow sx={parentRowSx}>
                    <TableCell sx={tableCellSx}>Time</TableCell>
                    <TableCell sx={tableCellSx}>Volume Path</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {queueData
                    .map((log, index) => (
                        <TableRow key={index} sx={childRowSx}>
                            <TableCell sx={tableCellSx}>
                                {formatIsoDateTime(log.timestamp, isLocalTime)}
                            </TableCell>
                            <TableCell sx={tableCellSx}>{log.volume_path}</TableCell>

                        </TableRow>
                    ))
                }
            </TableBody>
        </Table>
    }
    else {
        const openData = logSanitizer(
            logEntries.filter((e) => e.scan_id === openRow.scanId).sort((a, b) => a.created < b.created ? -1 : 1),
            isInternalOperator);
        fragment = <Table size="small" sx={{ minWidth: 550, maxWidth: 700, }} >
            <TableHead>
                <TableRow sx={parentRowSx}>
                    <TableCell sx={tableCellSx}>Time</TableCell>
                    <TableCell sx={tableCellSx}>Event</TableCell>
                    <TableCell sx={tableCellSx}>Status</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {openData
                    .map((log, index) => (
                        <TableRow key={index} sx={childRowSx}>
                            <TableCell sx={tableCellSx}>
                                {formatIsoDateTime(log.created, isLocalTime)}
                            </TableCell>
                            <TableCell sx={tableCellSx}>{log.event_type}</TableCell>
                            <TableCell sx={tableCellSx}>{log.status}</TableCell>
                        </TableRow>
                    ))}
            </TableBody>
        </Table>
    }
    return <TableRow>
        <TableCell sx={subTableWrapperRowSx} colSpan={6}>
            <Collapse in={openRowId === rowId} unmountOnExit>
                <Grid container sx={{ pl: 5, pt: 1, pb: 1, pr: 5, justifyContent: 'center', justifyItems: 'center' }}>
                    {fragment}
                </Grid>
            </Collapse>
        </TableCell>
    </TableRow>
}
