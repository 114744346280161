import { AutomatedInspectionMetrics, calculateMaxMin, findMetricName, getIdFromInternalName, getMetricWithStats, highlightAutomaticInspection } from "."
import { AnnotationShapeList, AnnotationShapeOptions, ManualMeasurementResults, MeasurementDimensions, PointGeometry, RawCathodeTraceResults, RawElectrodeOverhangTraceResults, RawGeneralInspectionResults } from "../../types"
import { colorAnnotation, overlayColorScheme } from "../colorScheme"

const determineShapeType = (metricName: AutomatedInspectionMetrics) => {
    switch (metricName) {
        case AutomatedInspectionMetrics.CORE_AREA:
            return AnnotationShapeOptions.POLYGON
        case AutomatedInspectionMetrics.CAN_WALL_THICKNESS_MEAN:
        case AutomatedInspectionMetrics.CAN_INNER_DIAMETER_MEAN:
        case AutomatedInspectionMetrics.CAN_OUTER_DIAMETER_MEAN:
            return AnnotationShapeOptions.POINTS
        case AutomatedInspectionMetrics.CORE_CIRCULARITY_DMIN_DMAX:
        case AutomatedInspectionMetrics.CAN_CIRCULARITY_DMIN_DMAX:
            return AnnotationShapeOptions.ELLIPSE_AND_LINES
        case AutomatedInspectionMetrics.CORE_CIRCULARITY_MCC:
            return AnnotationShapeOptions.CIRCLE_AND_LINE
        case AutomatedInspectionMetrics.CORE_CONCENTRICITY:
            return AnnotationShapeOptions.LINE
        case AutomatedInspectionMetrics.CORE_EFFECTIVE_DIAMETER:
            return AnnotationShapeOptions.NONE
        case AutomatedInspectionMetrics.CAN_MAX_DENTING:
            return AnnotationShapeOptions.ELLIPSE_AND_LINES
        default:
            return AnnotationShapeOptions.NONE // TODO: check this default assumption...
    }
}


export const getListOfShapes = (
    manualMeasurements: ManualMeasurementResults[],
    rawAutomatedInspectionResults: RawGeneralInspectionResults[],
    imageMeasurementPadding: { horizontal: number, vertical: number },
    availableMetrics: MeasurementDimensions[],
    highlightedMeasurement: { id: number, source: string },
    visible_slice_id: number,
    visibleInspectionMetricIds: number[],
    rawCathodeTraceResults: RawCathodeTraceResults[],
    RawElectrodeOverhangTraceResults: RawElectrodeOverhangTraceResults[],
) => {

    let combined = [] as AnnotationShapeList[]
    const highlightedId = highlightedMeasurement.id
    const highlightedSource = highlightedMeasurement.source


    const manual = manualMeasurements.filter((e) => e.display)
        .map((e) => {
            return {
                id: e.id,
                coordinates: e.coordinates.map((e: PointGeometry) => [e.x, e.y]), // TODO: could drop point geometry type
                shapeType: e.shapeType as string as AnnotationShapeOptions, // TODO: lazy but quick typing. slim opportunity for error here.
                color: ("manual" === highlightedSource) && (e.id === highlightedId) ? overlayColorScheme.red : overlayColorScheme.red.concat("98")
            } as AnnotationShapeList
        })
    combined.push(...manual)

    const automated = rawAutomatedInspectionResults
        .filter((e) => e.slice_id === visible_slice_id)
        .filter((e) => visibleInspectionMetricIds.includes(e.metric_id))
        .map((e) => {
            const metricName = findMetricName(e.metric_id, availableMetrics)
            return {
                id: e.metric_id,
                coordinates: e.shape_points,
                shapeType: determineShapeType(metricName),
                color: highlightAutomaticInspection(e.metric_id, colorAnnotation(metricName), highlightedId, highlightedSource)
            } as AnnotationShapeList
        })
    combined.push(...automated)

    const anodeAndCathodeShapes = getShapesForAnodesAndCathodes(visibleInspectionMetricIds, availableMetrics, highlightedId, rawCathodeTraceResults, RawElectrodeOverhangTraceResults, visible_slice_id)
    if (anodeAndCathodeShapes) combined.push(...anodeAndCathodeShapes)

    const canWallThicknessShapes = getCanWallThicknessShapes(rawAutomatedInspectionResults, availableMetrics, visibleInspectionMetricIds, visible_slice_id, highlightedId, highlightedSource)
    if (canWallThicknessShapes) combined.push(...canWallThicknessShapes)

    return combined.map((e: AnnotationShapeList, index) => ({
        ...e,
        id: index, // Potential gotcha here?
    }))
}

const colorAndHighlightMaxMin = (value: number, minMax: { min: number, max: number }, highlightedSubMetric: number) => { // shape points is the most reliable id here, I don't have the shape id.
    let color = overlayColorScheme.blue.concat("98")
    if (value === minMax.min) {
        color = overlayColorScheme.orange.concat("98")
        if (highlightedSubMetric === 1) color = overlayColorScheme.orange
    }
    if (value === minMax.max) {
        color = overlayColorScheme.green.concat("98")
        if (highlightedSubMetric === 2) color = overlayColorScheme.green
    }
    if ([3, 4].includes(highlightedSubMetric)) color = overlayColorScheme.blue
    return color
}

const generateCathodeTraceShapes = (
    rawCathodeTraceResults: RawCathodeTraceResults[],
    visible_slice_id: number,
    highlightedSubMetric: number,
) => {
    const cathodeTraceAtSlice = rawCathodeTraceResults
        .filter((e) => e.slice_id === visible_slice_id)

    const maxMinCathodeTraceLength = calculateMaxMin(cathodeTraceAtSlice.map((e) => e.trace_length))
    const cathodeTrace = cathodeTraceAtSlice
        .map((e) => ({
            id: 0,
            coordinates: e.shape_points,
            shapeType: AnnotationShapeOptions.LINE,
            color: colorAndHighlightMaxMin(e.trace_length, maxMinCathodeTraceLength, highlightedSubMetric)
        } as AnnotationShapeList))
    return cathodeTrace
}

const generateCathodePositionShapes = (
    rawCathodePositionResults: RawCathodeTraceResults[],
    visible_slice_id: number,
    location: "top" | "bottom",
    highlightedSubMetric: number
) => {
    const cathodePositionAtSlice = rawCathodePositionResults.filter((e) => e.slice_id === visible_slice_id)
    const pointIndex = location === "top" ? 1 : 0
    const data = cathodePositionAtSlice.map((e) => location === "top" ? e.top_position : e.bottom_position)
    const minMaxCathodePosition = calculateMaxMin(data)
    const cathodePosition = cathodePositionAtSlice
        .map((e) => ({
            id: 0,
            coordinates: [e.shape_points[pointIndex]],
            shapeType: AnnotationShapeOptions.POINTS,
            color: colorAndHighlightMaxMin(location === "top" ? e.top_position : e.bottom_position,
                minMaxCathodePosition, highlightedSubMetric)
        } as AnnotationShapeList))
    return cathodePosition
}

const generateElectrodeOverhangShapes = (
    RawElectrodeOverhangTraceResults: RawElectrodeOverhangTraceResults[],
    visible_slice_id: number,
    location: "top" | "bottom" | "all",
    highlightedSubMetric: number
) => {
    const electrodeOverhangTraceAtSlice = location === "all" ?
        RawElectrodeOverhangTraceResults
            .filter((e) => e.slice_id === visible_slice_id) :
        RawElectrodeOverhangTraceResults
            .filter((e) => e.slice_id === visible_slice_id)
            .filter((e) => e.location === location)

    const minMaxElectrodeOverhangTrace = calculateMaxMin(electrodeOverhangTraceAtSlice.map((e) => e.trace_length))
    const electrodeOverhangTrace = electrodeOverhangTraceAtSlice
        .map((e) => ({
            id: 0,
            coordinates: e.shape_points,
            shapeType: AnnotationShapeOptions.LINE,
            color: colorAndHighlightMaxMin(e.trace_length, minMaxElectrodeOverhangTrace, highlightedSubMetric)
        } as AnnotationShapeList))
    return electrodeOverhangTrace
}

export const getShapesForAnodesAndCathodes = (
    visibleInspectionMetricIds: number[],
    availableMetrics: MeasurementDimensions[],
    highlightedId: number,
    rawCathodeTraceResults: RawCathodeTraceResults[],
    RawElectrodeOverhangTraceResults: RawElectrodeOverhangTraceResults[],
    visible_slice_id: number
) => {
    // TODO could code golf this a bunch!
    let combined = [] as AnnotationShapeList[]
    const visibleMetricsWithStatsInternalNames = getMetricWithStats(visibleInspectionMetricIds).map(
        (e) => availableMetrics.find((f) => f.metric_id === e)?.metric_internal_name ?? -1
    )
    const availableMetricInternalNames = availableMetrics.map((e) => e.metric_internal_name)
    const highlightedMainMetric = Math.floor(highlightedId / 1000)
    const highlightedSubMetric = highlightedId % 1000
    const highlightedMainMetricName = findMetricName(highlightedMainMetric, availableMetrics)

    if (availableMetricInternalNames.includes(AutomatedInspectionMetrics.CATHODE_WIDTH) &&
        visibleMetricsWithStatsInternalNames.includes(AutomatedInspectionMetrics.CATHODE_WIDTH)
    ) {
        if (visibleInspectionMetricIds.includes(highlightedId) &&
            highlightedMainMetricName === AutomatedInspectionMetrics.CATHODE_WIDTH) {
            combined.push(...generateCathodeTraceShapes(rawCathodeTraceResults, visible_slice_id, highlightedSubMetric))
        } else {
            combined.push(...generateCathodeTraceShapes(rawCathodeTraceResults, visible_slice_id, 0))
        }
    }
    if (availableMetricInternalNames.includes(AutomatedInspectionMetrics.CATHODE_POSITION_TOP) &&
        visibleMetricsWithStatsInternalNames.includes(AutomatedInspectionMetrics.CATHODE_POSITION_TOP)
    ) {
        if ((visibleInspectionMetricIds.includes(highlightedId)) && highlightedMainMetricName === AutomatedInspectionMetrics.CATHODE_POSITION_TOP) {
            combined.push(...generateCathodePositionShapes(rawCathodeTraceResults, visible_slice_id, "top", highlightedSubMetric))
        }
        else {
            combined.push(...generateCathodePositionShapes(rawCathodeTraceResults, visible_slice_id, "top", 0))
        }

    }
    if (availableMetricInternalNames.includes(AutomatedInspectionMetrics.CATHODE_POSITION_BOTTOM) &&
        visibleMetricsWithStatsInternalNames.includes(AutomatedInspectionMetrics.CATHODE_POSITION_BOTTOM)
    ) {
        if ((visibleInspectionMetricIds.includes(highlightedId)) && highlightedMainMetricName === AutomatedInspectionMetrics.CATHODE_POSITION_BOTTOM) {
            combined.push(...generateCathodePositionShapes(rawCathodeTraceResults, visible_slice_id, "bottom", highlightedSubMetric))
        }
        else {
            combined.push(...generateCathodePositionShapes(rawCathodeTraceResults, visible_slice_id, "bottom", 0))
        }

    }
    // Electrode (anode) overhangs: Note we have overlapping shapes here... TODO: could consolidate this. Show only the relevant ones.
    if (availableMetricInternalNames.includes(AutomatedInspectionMetrics.ANODE_OVERHANG_ALL) &&
        visibleMetricsWithStatsInternalNames.includes(AutomatedInspectionMetrics.ANODE_OVERHANG_ALL)
    ) {
        if ((visibleInspectionMetricIds.includes(highlightedId)) && highlightedMainMetricName === AutomatedInspectionMetrics.ANODE_OVERHANG_ALL) {
            combined.push(...generateElectrodeOverhangShapes(RawElectrodeOverhangTraceResults, visible_slice_id, "all", highlightedSubMetric))
        }
        else {
            combined.push(...generateElectrodeOverhangShapes(RawElectrodeOverhangTraceResults, visible_slice_id, "all", 0))
        }
    }
    if (availableMetricInternalNames.includes(AutomatedInspectionMetrics.ANODE_OVERHANG_TOP) &&
        visibleMetricsWithStatsInternalNames.includes(AutomatedInspectionMetrics.ANODE_OVERHANG_TOP)
    ) {
        if ((visibleInspectionMetricIds.includes(highlightedId)) && highlightedMainMetricName === AutomatedInspectionMetrics.ANODE_OVERHANG_TOP) {
            combined.push(...generateElectrodeOverhangShapes(RawElectrodeOverhangTraceResults, visible_slice_id, "top", highlightedSubMetric))
        }
        else {
            combined.push(...generateElectrodeOverhangShapes(RawElectrodeOverhangTraceResults, visible_slice_id, "top", 0))
        }
    }
    if (availableMetricInternalNames.includes(AutomatedInspectionMetrics.ANODE_OVERHANG_BOTTOM) &&
        visibleMetricsWithStatsInternalNames.includes(AutomatedInspectionMetrics.ANODE_OVERHANG_BOTTOM)
    ) {
        if ((visibleInspectionMetricIds.includes(highlightedId)) && highlightedMainMetricName === AutomatedInspectionMetrics.ANODE_OVERHANG_BOTTOM) {
            combined.push(...generateElectrodeOverhangShapes(RawElectrodeOverhangTraceResults, visible_slice_id, "bottom", highlightedSubMetric))
        }
        else {
            combined.push(...generateElectrodeOverhangShapes(RawElectrodeOverhangTraceResults, visible_slice_id, "bottom", 0))
        }
    }
    return combined
}

export const getCanWallThicknessShapes = (
    rawAutomatedInspectionResults: RawGeneralInspectionResults[],
    availableMetrics: MeasurementDimensions[],
    visibleInspectionMetricIds: number[],
    visible_slice_id: number,
    highlightedId: number,
    highlightedSource: string
) => {
    const availableInternalNames = availableMetrics.map((e) => e.metric_internal_name)
    let canWallThicknessId = availableMetrics
        .find((e) => e.metric_internal_name === AutomatedInspectionMetrics.CAN_WALL_THICKNESS_MEAN)?.metric_id as number
    if (visibleInspectionMetricIds.includes(canWallThicknessId)
        && availableInternalNames.includes(AutomatedInspectionMetrics.CAN_INNER_DIAMETER_MEAN)
        && availableInternalNames.includes(AutomatedInspectionMetrics.CAN_OUTER_DIAMETER_MEAN)) {
        const canInnerMetricId = getIdFromInternalName(AutomatedInspectionMetrics.CAN_INNER_DIAMETER_MEAN, availableMetrics)
        const canOuterMetricId = getIdFromInternalName(AutomatedInspectionMetrics.CAN_OUTER_DIAMETER_MEAN, availableMetrics)
        const canWallThickness = rawAutomatedInspectionResults
            .filter((e) => e.slice_id === visible_slice_id)
            .filter((e) => [canInnerMetricId, canOuterMetricId].includes(e.metric_id))
            .map((e) => ({
                id: e.metric_id,
                coordinates: e.shape_points,
                shapeType: AnnotationShapeOptions.POINTS,
                color: highlightAutomaticInspection(canWallThicknessId, colorAnnotation(AutomatedInspectionMetrics.CAN_WALL_THICKNESS_MEAN), highlightedId, highlightedSource)
            }))
        return canWallThickness
    }
    return null
}
