import { Typography } from '@mui/material';
import { PostingApiStatus } from '../../types';

export default function LoadingMessage(
    { uploadState }: { uploadState: PostingApiStatus }
) {
    const color = uploadState === PostingApiStatus.ERROR ? "red" : "white"
    return <Typography variant="body1" sx={{ mt: 1, mb: 2, color }}>
        {uploadState === PostingApiStatus.LOADING ? "Loading" : null}
        {uploadState === PostingApiStatus.ERROR ? "Something went wrong :(" : null}
        {uploadState === PostingApiStatus.SUCCESS ? "Success!" : null}
    </Typography>
};
