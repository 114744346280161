import { CommonSvgShapes } from "../../../types";


export default function MeasurementLayerAreaSvgs(
    { areas, strokeWidth }: {
        areas: CommonSvgShapes[], // TODO: any type
        strokeWidth: string | number,
    }
) {
    function applyTransparency(colorString: string, transparency: number): string {
        // Worth a note: if there's transparency, the hex code is longer (#18A0FF98)
        // if no transparency (because it's 'highlighted') the hex code is shorter (#18A0FF)
        if (colorString.length <= 7) return colorString + transparency * 2 * 100
        return colorString.slice(0, -2) + transparency * 100
    }
    return <>
        {
            areas.map(e => (
                <polygon key={e.id}
                    // TODO: any type:
                    points={e.coordinates.map((point: number[]) => `${point[0]},${point[1]}`).join(" ")}
                    fill={applyTransparency(e.color, 0.2)}
                    stroke={e.color}
                    strokeWidth={strokeWidth}
                />
            ))
        }
    </>
}
