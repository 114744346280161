import { useAuth0 } from '@auth0/auth0-react';
import { Card, CircularProgress, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { ApiEndpoints, axiosConfigBase } from '../../utils/apiUtils';
import TableToCsvCopy from './TableToCsvCopy';
import TableViewer from './TableViewer';
import { InternalPageMenuOptions } from './internalTypes';


export default function TableLayoutComponent({ selectedTable }:
    { selectedTable: InternalPageMenuOptions; }
) {
    // const [selectedTable, setSelectedTable] = useState(InternalPageMenuOptions.REQUESTS);
    const [filterText, setFilterText] = useState('');
    const [sortedColumn, setSortedColumn] = useState("id");
    const [sortDirection, setSortDirection] = useState<'asc' | "desc">('desc');
    const [tableData, setTableData] = useState<any[]>([]);
    const [loading, setLoading] = useState("success");

    useEffect(() => {
        handleChange(selectedTable)
    }, [selectedTable])

    const handleChange = (selection: InternalPageMenuOptions) => {
        setTableData([])
        switch (selection) {
            case InternalPageMenuOptions.REQUESTS:
                queryApi(ApiEndpoints.INTERNAL_REQUESTS)
                break;
            case InternalPageMenuOptions.SCAN_TYPES:
                queryApi(ApiEndpoints.INTERNAL_SCAN_TYPE)
                break;
            case InternalPageMenuOptions.CELL_MODELS:
                queryApi(ApiEndpoints.INTERNAL_CELL_MODEL)
                break;
            case InternalPageMenuOptions.REQUEST_GROUPS:
                queryApi(ApiEndpoints.INTERNAL_REQUEST_GROUP)
                break;
            case InternalPageMenuOptions.REQUEST_GROUP_MEMBERSHIP:
                queryApi(ApiEndpoints.INTERNAL_MEMBERSHIPS)
                break;
            case InternalPageMenuOptions.USERS:
                queryApi(ApiEndpoints.INTERNAL_USERS)
                break;
            case InternalPageMenuOptions.SCANNER:
                queryApi(ApiEndpoints.INTERNAL_SCANNERS)
                break;
            case InternalPageMenuOptions.SCANS:
                queryApi(ApiEndpoints.INTERNAL_SCANS)
                break;
            case InternalPageMenuOptions.COMPANIES:
                queryApi(ApiEndpoints.INTERNAL_COMPANIES)
                break;
            case InternalPageMenuOptions.TOS_RECORDS:
                queryApi(ApiEndpoints.INTERNAL_TOS_RECORDS)
                break;
            case InternalPageMenuOptions.CELLS:
                queryApi(ApiEndpoints.INTERNAL_CELLS)
                break;
            case InternalPageMenuOptions.USER_LOGS:
                queryApi(ApiEndpoints.INTERNAL_USER_LOGS)
                break;
        }
    };
    useEffect(() => {
        queryApi(ApiEndpoints.INTERNAL_REQUESTS)
    }, [])
    const { getIdTokenClaims } = useAuth0();
    const queryApi = async (endpoint: ApiEndpoints) => {
        const token = await getIdTokenClaims().then((claims) => claims ? claims.__raw : null);
        if (!token) return;
        setLoading("loading")
        try {
            const response = await axios.get(endpoint, axiosConfigBase(token));
            setTableData(response.data)
            setLoading("success")
        }
        catch (error) {
            setLoading("error")
            console.error(error)
        }
    }
    const handleSortClick = (column: any) => {
        if (column === sortedColumn) {
            setSortDirection((prev) => (prev === 'asc' ? 'desc' : 'asc'));
        } else {
            setSortedColumn(column);
            setSortDirection('asc');
        }
    };
    const filteredData = tableData?.sort((a: any, b: any) => {
        if (sortedColumn === null) return 0;
        if (a[sortedColumn] < b[sortedColumn]) {
            return sortDirection === 'asc' ? -1 : 1;
        }
        if (a[sortedColumn] > b[sortedColumn]) {
            return sortDirection === 'asc' ? 1 : -1;
        }
        return 0;
    }).filter((e: any) => {
        return JSON.stringify(e).toLowerCase().includes(filterText.toLowerCase()) || filterText === ''
    })
    return (
        <>

            <TextField
                id="outlined-basic"
                label="Quick Filter"
                variant="outlined"
                sx={{ mt: 2, width: 400 }}
                fullWidth
                value={filterText}
                onChange={(e) => setFilterText(e.target.value)}
            />
            <Typography variant="body2" sx={{ flexGrow: 1, pt: "14pt", }}>
                Viewing table: {selectedTable}
                {filterText === '' ? null : <> &nbsp; | &nbsp; Filtering for "{filterText}"</>}
                &nbsp;&nbsp; | &nbsp; Number of entries: {filteredData.length.toString()}
                {sortedColumn === null ? null : <> &nbsp; | &nbsp; Sorting '{sortedColumn}' {sortDirection}</>}
                &nbsp; |  &nbsp; <TableToCsvCopy tableData={filteredData} fileName='name.csv' />
            </Typography>
            <Typography variant="body1" >
                {
                    loading === 'loading' ? <> Loading...  <CircularProgress size={20} /> </> :
                        loading === 'error' ? <> <b>Something went wrong! </b></> : null
                }
            </Typography>
            <Card sx={{ mt: 1, mb: 4, height: "100%" }} elevation={2}>
                {tableData.length === 0 ? null :
                    <TableViewer
                        tableData={filteredData}
                        sortedColumn={sortedColumn}
                        sortDirection={sortDirection}
                        handleSortClick={handleSortClick}
                        selectedTable={selectedTable}
                    />
                }
            </Card>
        </>
    );
}
