import { CommonSvgShapes } from "../../../types";


export default function MeasurementLayerPointsSvgs(
    {
        points,
        strokeWidth
    }: {
        points: CommonSvgShapes[],
        strokeWidth: string | number,
    }
) {
    return <>
        {points.map((shape) => (
            shape.coordinates.map((e, index: number) => (
                <circle key={index}
                    cx={e[0]}
                    cy={e[1]}
                    r={Number(strokeWidth) * 2}
                    fill={shape.color}
                />
            ))
        ))
        }
    </>
}
