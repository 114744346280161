import CopyAllIcon from '@mui/icons-material/CopyAll';
import { IconButton, Tooltip } from "@mui/material";

export default function TableToCsvCopy({ tableData, fileName }: {
    tableData: any[];
    fileName: string;
}) {
    // Convert the table data to CSV format
    const convertToCSV = () => {
        const rows = tableData.map(row => Object.values(row).join(',')); // Assuming each row is an object

        // Add headers if needed
        const header = Object.keys(tableData[0]).join(',');
        const csvData = `${header}\n${rows.join('\n')}`;

        return csvData;
    };

    // Copy CSV data to the clipboard using navigator.clipboard
    const copyToClipboard = async () => {
        try {
            const csvData = convertToCSV();
            await navigator.clipboard.writeText(csvData);
            alert('CSV data copied to clipboard!');
        } catch (error) {
            console.error('Unable to copy to clipboard', error);
        }
    };

    return (
        <Tooltip title="Copy csv to clipboard">
            <IconButton onClick={copyToClipboard}>
                <CopyAllIcon />
            </IconButton>
        </Tooltip>
    );
};
