import { CircularProgress, Fade, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useEffect, useState } from 'react';

export default function SubscriptionLoadingPage() {
    const [fadeIn, setFadeIn] = useState(false)
    useEffect(() => {
        setFadeIn(true)
        return () => { setFadeIn(false) }
    }, [])
    return (
        <Fade in={fadeIn} >
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
            >
                <Typography variant="h5" component="div" color='#FFF' sx={{ flexGrow: 1, marginTop: "20px" }}>
                    Checking your subscription...
                </Typography>
                <CircularProgress />
            </Grid>
        </ Fade>
    );
}
