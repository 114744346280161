import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { ScanInfo } from '../../types';
import { formatIsoDate } from '../../utils';


export default function ScanMetaData(
    { scanData, voxelSize }: { scanData: ScanInfo, voxelSize: number }
) {
    const cardContentToDisplay = [
        { key: 'Batch Name', value: scanData.request_name, size: 4 },
        { key: 'Cell Model', value: scanData.cell_model_name, size: 2 },
        { key: "Producer", value: scanData.cell_model_vendor, size: 2 },
        { key: 'Serial Number', value: scanData.cell_sn, size: 3 },
        { key: 'Scan ID', value: scanData.scan_id, size: 2 },
        { key: 'Scan Date', value: scanData.post_processing_completed.toString().split('T')[0], size: 2 },
        { key: 'Voxel Size', value: (voxelSize * 1000).toFixed(1) + " µm", size: 2 },
    ]

    return (
        <TableContainer sx={{ maxWidth: 1300 }}>
            <Table size="small" sx={{ minWidth: 650 }} >
                <TableHead>
                    <TableRow
                        sx={{ borderTop: 0.2, borderColor: "#ffffff36", backgroundColor: "#ffffff09" }}
                    >
                        {
                            cardContentToDisplay.map((e) => (
                                <TableCell key={e.key} sx={{ border: 0 }}>
                                    {e.key}
                                </TableCell>
                            ))
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow
                        key={scanData.scan_id}
                        sx={{ borderTop: 0.2, borderBottom: 0.2, borderColor: "#ffffff36" }}
                    >
                        <TableCell component="th" sx={{ border: 0 }} scope="row">
                            {scanData.request_name}
                        </TableCell>
                        <TableCell sx={{ border: 0 }}>{scanData.cell_model_name}</TableCell>
                        <TableCell sx={{ border: 0 }}>{scanData.cell_model_vendor}</TableCell>
                        <TableCell sx={{ border: 0 }}>{scanData.cell_sn}</TableCell>
                        <TableCell sx={{ border: 0 }}>
                            {scanData.scan_id}
                        </TableCell>
                        <TableCell sx={{ border: 0 }}>
                            {formatIsoDate(scanData.post_processing_completed)}
                        </TableCell>
                        <TableCell sx={{ border: 0 }}>
                            {(voxelSize * 1000).toFixed(1) + " µm"}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}
