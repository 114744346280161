import { useAuth0 } from '@auth0/auth0-react';
import BatteryAlertIcon from '@mui/icons-material/BatteryAlert';
import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';
import { Autocomplete, Button, Card, CardContent, Checkbox, Divider, FormControl, Grid, TextField, Typography } from '@mui/material';
import axios from 'axios';
import { Fragment, useEffect, useState } from 'react';
import { PostingApiStatus, ScanAxes } from '../../types';
import { axiosConfigBase } from '../../utils/apiUtils';
import LoadingMessage from './LoadingMessage';
import ToggleScanFlag from './ToggleScanFlag';
import { dropdownPopulator, formFieldTextEditor, formInputFields, formValidator, formatDropdownOptions, parseIdFromString, setStateFromSelectedTable } from './internalPageUtils';
import { AddCellModelObject, AddCompanyObject, AddMembershipObject, AddRequestGroupObject, AddRequestObject, AddScanTypeObject, AddUserObject, InternalPageMenuOptions } from './internalTypes';


export default function AddItemForm(
    { selectedTable }: { selectedTable: InternalPageMenuOptions }
) {
    const { getIdTokenClaims } = useAuth0();
    const [isFormComplete, setIsFormComplete] = useState(false);
    const [uploadState, setUploadState] = useState(PostingApiStatus.FALSE);
    const [formData, setFormData] = useState<
        AddMembershipObject |
        AddCellModelObject |
        AddScanTypeObject |
        AddRequestGroupObject |
        AddRequestObject |
        AddCompanyObject |
        AddUserObject>(setStateFromSelectedTable(selectedTable));

    const [dropdownOptions, setDropdownOptions] = useState<any[]>(
        formInputFields(selectedTable).dropdowns.map((e: string) => { return { name: e, options: [] } }));

    useEffect(() => {
        setFormData(setStateFromSelectedTable(selectedTable))
        setDropdownOptions(formInputFields(selectedTable).dropdowns.map((e: string) => { return { name: e, options: [] } }))
        populateDropdowns(selectedTable)
    }, [selectedTable])

    // Check form completion status whenever form data changes
    useEffect(() => {
        const formComplete = formValidator(formData, selectedTable);
        if (formComplete) { setIsFormComplete(true); }
        else { setIsFormComplete(false); }
    }, [formData]);

    const handleCheckboxChange = (e: any) => {
        const { name, checked } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: checked,
        }));
    }

    // State to track form completion status
    // Handle form input changes
    const handleInputChange = (e: any) => {
        const { name, value } = e.target;
        if (selectedTable === InternalPageMenuOptions.SCAN_TYPES && ["VoxelsX", "VoxelsY", "VoxelsZ"].includes(name)) {
            const prev = formData as AddScanTypeObject
            const updatedForm = {
                ...prev,
                data: {
                    ...prev.data,
                    [name]: +value
                }
            } as AddScanTypeObject
            setFormData(updatedForm);
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };

    const handleAutocompleteChange = (field: string, selected_item: string) => {
        // Measured dims are a lil annoying to construct, we'll refactor when we change the DB:
        if (field === "axes" && selectedTable === InternalPageMenuOptions.CELL_MODELS) {
            const prev = formData as AddCellModelObject
            const cyl = { can_diameter_mm: 0, can_height_mm: prev.data.can_height_mm }
            const xyz = { can_depth_mm: 0, can_width_mm: 0, can_height_mm: prev.data.can_height_mm }
            const updatedForm = {
                axes: selected_item,
                data: selected_item === ScanAxes.CYL ? cyl : xyz,
                form_factor: prev.form_factor,
                name: prev.name,
                updated: null,
                vendor: prev.vendor,
            } as AddCellModelObject
            setFormData(updatedForm);
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [field]: field.includes("id") ? parseIdFromString(selected_item) : selected_item,
            }));
        }
    }

    // Handle form submission
    const handleSubmit = async (e: React.FormEvent) => {
        setUploadState(PostingApiStatus.LOADING)
        const token = await getIdTokenClaims().then((claims) => claims ? claims.__raw : null);
        if (!token) return;
        if (token) {
            const endpoint = formInputFields(selectedTable).endpoint
            axios.post(endpoint, formData, axiosConfigBase(token))
                .then((response) => {
                    setUploadState(PostingApiStatus.SUCCESS)
                }).then(() => {
                    setTimeout(() => {
                        window.location.reload()
                    }, 1000)
                })
                .catch((error) => {
                    console.error(error)
                    setUploadState(PostingApiStatus.ERROR)
                });
        }
    };

    // construct dropdown options
    const populateDropdowns = async (selectedTable: InternalPageMenuOptions) => {
        const token = await getIdTokenClaims().then((claims) => claims ? claims.__raw : null);
        if (!token) return;
        try {
            formInputFields(selectedTable).dropdowns.forEach(async (e: string) => {
                const dropdown = dropdownPopulator(e)
                let options: any[] = []
                if (!dropdown) return
                if (dropdown.endpoint && dropdown.api) {
                    const response = await axios.get(dropdown.endpoint, axiosConfigBase(token));
                    options = response.data
                } else {
                    options = dropdown.enum ? dropdown.enum : []
                }
                setDropdownOptions((prevOptions) => {
                    return prevOptions.map((option) => {
                        if (option.name === e) {
                            return { name: e, options: options }
                        }
                        else { return option }
                    })
                })
            })
        } catch (error) {
            console.log(error);
        }
    }
    const dropdowns = () => {
        return formInputFields(selectedTable).dropdowns.map((e: string) => {
            const selectValue = (formItem: string) => {
                switch (selectedTable) {
                    case InternalPageMenuOptions.REQUESTS:
                        return (formData as AddRequestObject)[formItem as keyof AddRequestObject]
                    case InternalPageMenuOptions.SCAN_TYPES:
                        return (formData as AddScanTypeObject)[formItem as keyof AddScanTypeObject]
                    case InternalPageMenuOptions.CELL_MODELS:
                        return (formData as AddCellModelObject)[formItem as keyof AddCellModelObject]
                    case InternalPageMenuOptions.REQUEST_GROUPS:
                        return (formData as AddRequestObject)[formItem as keyof AddRequestObject]
                    case InternalPageMenuOptions.USERS:
                        return (formData as AddRequestObject)[formItem as keyof AddRequestObject]
                    case InternalPageMenuOptions.REQUEST_GROUP_MEMBERSHIP:
                        return (formData as AddRequestObject)[formItem as keyof AddRequestObject]
                }
            }
            if (dropdownOptions.length === 0 || !dropdownOptions || selectValue(e) === undefined) return null
            const autoCompleteOptions = dropdownOptions.filter((option) => option.name === e)[0].options
                .map((optionItem: any) => {
                    return formatDropdownOptions(e, optionItem)
                })
            return (<Fragment key={e}>
                <Grid item xs={4} sx={{ textAlign: 'right' }}>
                    <Typography variant="body2" sx={{ mt: 1 }}>{formFieldTextEditor(e)}</Typography>
                </Grid>
                <Grid item xs={8}>
                    <FormControl fullWidth size="small">
                        {dropdownOptions.length > 0 &&
                            <>
                                <Autocomplete
                                    disableClearable
                                    size="small"
                                    options={autoCompleteOptions}
                                    renderInput={(params) => <TextField {...params} />}
                                    onChange={(event, newValue) => { handleAutocompleteChange(e, newValue as string) }}
                                />
                            </>}
                    </FormControl>
                </Grid>
            </ Fragment >
            )
        })
    }

    const checkboxes = () => {
        return formInputFields(selectedTable).checkboxes.map((e: string) => {
            return (<Fragment key={e}>
                <Grid item xs={4} sx={{ textAlign: 'right' }}>
                    <Typography variant="body2" sx={{ mt: 1 }}>{formFieldTextEditor(e)}</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Checkbox name={e} onClick={handleCheckboxChange} />
                </Grid>
            </Fragment>
            )
        })
    }

    const textFields = () => {
        return formInputFields(selectedTable).textFields.map((e: string) => {
            return (<Fragment key={e}>
                <Grid item xs={4} sx={{ textAlign: 'right' }}>
                    <Typography variant="body2" sx={{ mt: 1 }}>{formFieldTextEditor(e)}</Typography>
                </Grid>
                <Grid item xs={8}>
                    <TextField
                        fullWidth
                        size="small"
                        name={e}
                        onChange={handleInputChange}
                    />
                </Grid>
            </Fragment>
            )
        })
    }

    const numberFields = () => {
        return formInputFields(selectedTable).numberFields.map((e: string) => {
            return (<Fragment key={e}>
                <Grid item xs={4} sx={{ textAlign: 'right' }}>
                    <Typography variant="body2" sx={{ mt: 1 }}>{formFieldTextEditor(e)}</Typography>
                </Grid>
                <Grid item xs={8}>
                    <TextField
                        size="small"
                        inputProps={{ type: 'number' }}
                        name={e}
                        onChange={handleInputChange}
                    />
                </Grid>
            </Fragment>
            )
        })
    }


    const handleCellDimensionsChange = (e: any) => {
        // Temporary and not great function until we encode the measured dimensions in the DB differently.
        const { name, value } = e.target;
        const prev = formData as AddCellModelObject

        const updatedForm = {
            ...prev,
            data: {
                ...prev.data,
                [name]: +value
            }
        } as AddCellModelObject
        setFormData(updatedForm);
    }
    const measuredDimensionsTemporary = () => {
        // Temporary and not great function until we encode the measured dimensions in the DB differently.
        if (selectedTable === InternalPageMenuOptions.CELL_MODELS &&
            'axes' in formData &&
            [ScanAxes.CYL, ScanAxes.XYZ].includes(formData.axes as ScanAxes)) {
            // can_diameter_mm, can_height_mm
            let metrics = ['can_diameter_mm', 'can_height_mm']
            if (formData.axes === ScanAxes.XYZ) {
                metrics = ['can_width_mm', 'can_depth_mm', 'can_height_mm']
            }
            return metrics.map((e: string) => {
                return (<Fragment key={e}>
                    <Grid item xs={4} sx={{ textAlign: 'right' }}>
                        <Typography variant="body2" sx={{ mt: 1 }}>{formFieldTextEditor(e)}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            size="small"
                            inputProps={{ type: 'number' }}
                            name={e}
                            onChange={handleCellDimensionsChange}
                        />
                    </Grid>
                </Fragment>
                )
            })

        }
        return null
    }
    if ([
        InternalPageMenuOptions.REQUESTS,
        InternalPageMenuOptions.SCAN_TYPES,
        InternalPageMenuOptions.CELL_MODELS,
        InternalPageMenuOptions.USERS,
        InternalPageMenuOptions.REQUEST_GROUP_MEMBERSHIP,
        InternalPageMenuOptions.REQUEST_GROUPS,
        InternalPageMenuOptions.COMPANIES,
    ].includes(selectedTable)) return (
        <Card sx={{ m: 1, width: 900 }} elevation={2}>
            <CardContent>
                <Typography variant="h5" sx={{ mb: 2, textAlign: 'center' }}>
                    Form for adding to: {selectedTable}
                </Typography>
                <Grid container spacing={2}>
                    {[textFields(), dropdowns(), numberFields(), checkboxes(), measuredDimensionsTemporary()]}
                </Grid>
                <Divider sx={{ mt: 2, mb: 2 }} />
                <Grid container spacing={2} sx={{}}>
                    <Grid item xs={4}>
                        <Typography variant="body2" sx={{ mt: 1, textAlign: 'right' }}>
                            Resulting Data to Upload {
                                isFormComplete ?
                                    <BatteryChargingFullIcon sx={{ color: "lime", mb: -1 }} /> :
                                    <BatteryAlertIcon sx={{ color: "orange", mb: -1 }} />
                            }
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Card>
                            <Typography variant="caption" sx={{ m: 1, wordBreak: "break-word", color: isFormComplete ? "lime" : "orange" }}>
                                {JSON.stringify(formData)}
                            </Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="body2" sx={{ mt: 1, textAlign: 'right' }}>
                            Submit:
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Button
                            type="submit"
                            variant="outlined"
                            color="primary"
                            disabled={!isFormComplete}
                            onClick={handleSubmit}
                        >
                            Submit
                        </Button>
                        <LoadingMessage uploadState={uploadState} />
                    </Grid>
                </Grid>
            </CardContent>
        </Card >
    );
    if (selectedTable === InternalPageMenuOptions.SCANS) return (
        <ToggleScanFlag />
    );

    return <>
        <Card sx={{ m: 1, width: 900 }} elevation={2}>
            <CardContent>
                <Typography variant="body1" sx={{ flexGrow: 1, pt: "14pt", }}> Must be added elsewhere right now, talk to Patrick or Owen for manual DB entry</Typography>
            </CardContent>
        </Card>
    </>
};
