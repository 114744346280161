import { CommonSvgShapes } from "../../../types";

export default function MeasurementLayerLineSvgs(
    { lines, strokeWidth }: {
        lines: CommonSvgShapes[],
        strokeWidth: string | number,
    }
) {
    return (
        <>
            {lines.map(e => {
                const points = e.coordinates.map(coord => `${coord[0]},${coord[1]}`).join(' ');
                return (
                    <polyline key={e.id}
                        points={points}
                        stroke={e.color}
                        strokeWidth={strokeWidth}
                        fill="none" />
                );
            })}
        </>
    );
}
