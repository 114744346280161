import LiveHelpOutlinedIcon from '@mui/icons-material/LiveHelpOutlined';
import IconButton from '@mui/material/IconButton';
import Tooltip from "@mui/material/Tooltip";
import Typography from '@mui/material/Typography';
export default function HelpButton() {
    const copyEmailToClipboard = () => {
        navigator.clipboard.writeText('support@glimp.se').then(function (x) {
            alert("Copied 'support@glimp.se' to clipboard.");
        });
    }

    return (
        <Tooltip title={<Typography variant="body1">Email technical support</Typography>}>
            <IconButton onClick={copyEmailToClipboard} >
                <LiveHelpOutlinedIcon />
            </IconButton>
        </Tooltip>
    )
};
