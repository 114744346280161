import { useAuth0 } from '@auth0/auth0-react';
import { Button, Card, CardContent, Input, Typography } from '@mui/material';
import axios from 'axios';
import { useState } from 'react';
import { PostingApiStatus } from '../../types';
import { ApiEndpoints } from '../../utils/apiUtils';
import LoadingMessage from './LoadingMessage';


export default function MoveRequestForm() {
    const { getIdTokenClaims } = useAuth0();
    const [uploadState, setUploadState] = useState(PostingApiStatus.FALSE);
    const [requestGroupId, setRequestGroupId] = useState(0);
    const [requestId, setRequestId] = useState(0);

    const handleButtonClick = async () => {
        const token = await getIdTokenClaims().then((claims) => claims ? claims.__raw : null);;
        if (requestGroupId === 0 || requestId === 0) return;
        if (token === null) return;
        axios({
            method: 'put',
            url: ApiEndpoints.INTERNAL_REQUESTS,
            headers: { Authorization: "Bearer " + token },
            params: { request_id: requestId, request_group_id: requestGroupId },
            baseURL: process.env.REACT_APP_API_BASEURL
        }).then((response) => {
            setUploadState(PostingApiStatus.SUCCESS)
        }).then(() => {
            setTimeout(() => {
                window.location.reload()
            }, 1000)
        })
            .catch((error) => {
                console.error(error)
                setUploadState(PostingApiStatus.ERROR)
            });
    }


    return <>
        <Card sx={{ m: 1, width: 900 }} elevation={2}>
            <CardContent>
                <Typography variant="h5" >
                    Move request from one request_group to another
                </Typography>
                <br />
                <Typography variant="body1" >
                    request_id to change:
                </Typography>
                <Input
                    type='number'
                    value={requestId}
                    onChange={(e) => setRequestId(Number(e.target.value))}
                />
                <br />
                <br />
                <Typography variant="body1" >
                    move to request_group_id:
                </Typography>
                <Input
                    type='number'
                    value={requestGroupId}
                    onChange={(e) => setRequestGroupId(Number(e.target.value))}
                />
                <br />
                <Button onClick={handleButtonClick}>Submit</Button>
                <LoadingMessage uploadState={uploadState} />
                <Typography variant="body2" >
                    Note: this is not a dropdown yet because that's more work. Sorry you have to put in the numbers manually, double check them!
                </Typography>
            </CardContent>
        </Card >
    </>
};
