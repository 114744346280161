import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import IconButton from '@mui/material/IconButton';
import Tooltip from "@mui/material/Tooltip";
import Typography from '@mui/material/Typography';

export default function ContactSales() {
    const openLinkInNewTab = () => {
        window.open('https://glimp.se/contact-us', '_blank');
    };

    return (
        <Tooltip title={<Typography variant="body1">Contact sales</Typography>}>
            <IconButton onClick={openLinkInNewTab} >
                <LocalPhoneOutlinedIcon />
            </IconButton>
        </Tooltip>
    )
};
