import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';

export default function NotFoundPage() {
    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
        >
            <Typography variant="h5" component="div" color='#FFF' sx={{ flexGrow: 1, mt: 8 }}>
                Page not found
            </Typography>
            <Typography variant="body1" color='#FFF'>
                Go back, or clear the url and try again. Please let us know if this keeps happening.
            </Typography>
        </Grid>
    );
}
