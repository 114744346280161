import { Fragment } from "react";

export interface CircleAndLineSvgShapes {
    id: number;
    coordinates: [number, number, number, number, number, number, number];
    color: string;
}
export default function MeasurementLayerCircleAndLineSvgs(
    {
        circlesAndLines,
        strokeWidth
    }: {
        circlesAndLines: CircleAndLineSvgShapes[],
        strokeWidth: string | number,
    }
) {
    return <>
        {circlesAndLines.map((e, index) => (
            <Fragment key={index}>
                <circle
                    cx={e.coordinates[0]}
                    cy={e.coordinates[1]}
                    r={e.coordinates[2]}
                    stroke={e.color}
                    fill="transparent"
                    strokeWidth={Number(strokeWidth) * 3}
                />
                <line
                    x1={e.coordinates[3]}
                    y1={e.coordinates[4]}
                    x2={e.coordinates[5]}
                    y2={e.coordinates[6]}
                    stroke={e.color}
                    strokeWidth={Number(strokeWidth) * 3}
                />
            </Fragment>
        ))

        }
    </>
}
