import { Stack, Typography } from '@mui/material';
import Slider from '@mui/material/Slider';
import { SliceOrientation, SliceRow } from '../../types';
import { minMaxSliderValues } from '../../utils';

export default function SliceSlider(
    { sliceList, sliceOrientation, sliderValue, handleSliderChange
    }: {
        sliceList: SliceRow[],
        sliceOrientation: SliceOrientation,
        sliderValue: number,
        handleSliderChange: (event: Event, newValue: number | number[]) => void,
    }
) {
    const minMax = minMaxSliderValues(sliceList)
    const positionUnit = sliceOrientation === SliceOrientation.AXIAL ? " deg" : " mm"
    const sliderValues = sliceList.map((slice: SliceRow) =>
    ({
        value: slice.position,
        label: null,
    }))


    return <Stack spacing={2} sx={{ pt: 1, pb: 1, pl: 1, height: '100%' }} justifyContent="center" justifyItems={"center"} alignItems={"center"}
    >
        <Typography variant='body2'>{minMax.max + positionUnit}</Typography>
        <Slider
            orientation='vertical'
            min={minMax.min}
            max={minMax.max}
            marks={sliderValues}
            step={null}
            track={false}
            valueLabelDisplay="on"
            value={typeof sliderValue === 'number' ? sliderValue : 0}
            onChange={handleSliderChange}
            sx={{
                pl: 8,
                flexGrow: 1,
                '& .MuiSlider-thumb': {
                    height: 4,
                    width: 15,
                    borderRadius: '3px',
                },
                '& .MuiSlider-valueLabel': {
                    backgroundColor: 'unset',
                    padding: '0px',
                    margin: '0px',
                }
            }}
        />
        <Typography variant='body2'>{minMax.min + positionUnit}</Typography>
    </Stack>
}
