import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { PageOptions, UserAccessState } from '../types';
import ContactSales from './ContactSales';
import DemoToggleButton from './DemoToggle';
import { ReactComponent as Logo } from "./GlimpseLogo.svg";
import HelpButton from './HelpButton';
import LogoutButton from './LogoutButton';
import UserButtonHeader from './UserButtonHeader';

export default function TopNavigationBar(
    {
        isDemoMode,
        handleDemoToggle,
        userAccessState,
        selectedPage
    }:
        {
            isDemoMode: boolean, handleDemoToggle: () => void,
            userAccessState: UserAccessState,
            selectedPage: PageOptions
        }
) {
    const logoStyle = {
        display: 'flex',
        height: '80px',
        marginTop: '-40px',
    }
    return (
        <AppBar position="fixed" sx={{ zIndex: 2000, height: 64 }}>
            <Toolbar>
                <Logo style={logoStyle} />
                <Typography variant='h6' align='left' sx={{ pl: 3, pt: 1, flexGrow: 1, color: 'red' }} >
                </Typography>

                <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
                </Typography>
                {userAccessState !== UserAccessState.LOADING && selectedPage !== PageOptions.TERMS &&
                    <DemoToggleButton
                        isDemoMode={isDemoMode}
                        handleDemoToggle={handleDemoToggle}
                        userAccessState={userAccessState} />
                }
                <UserButtonHeader />
                <ContactSales />
                <HelpButton />
                <LogoutButton />
            </Toolbar>
        </AppBar >
    )
}
