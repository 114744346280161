import DownloadIcon from '@mui/icons-material/Download';
import { IconButton, Tooltip, Typography } from '@mui/material';
import { ParentTableData } from './utils';

export default function DownloadParentTable(
    { tableData }: { tableData: ParentTableData[] }) {
    function downloadCsv(data: ParentTableData[], filename: string) {
        // Filter out some fields
        const filteredData = data.map(({ minCreated, maxCreated, queueId, ...rest }) => rest);

        const headers = Object.keys(filteredData[0]) as Array<keyof typeof filteredData[0]>;
        const csvContent = [
            headers.join(','), // header row first
            ...filteredData.map(row => headers.map(header => JSON.stringify(row[header])).join(','))
        ].join('\r\n');
        const blob = new Blob([csvContent], { type: 'text/csv' });

        // Create a link element
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return <Tooltip
        title={<Typography variant="body1">Download parent table data as a CSV</Typography>}>
        <IconButton onClick={() => downloadCsv(tableData, 'operator_table.csv')}>
            <DownloadIcon />
        </IconButton>
    </Tooltip>
}
