import { OrientationGuideLines } from "../../types";
import { overlayColorScheme } from "../../utils/colorScheme";


export default function OrientationGuides(
    { lines, strokeWidth, fontSize }: {
        lines: OrientationGuideLines[] | null,
        strokeWidth: string | number,
        fontSize: string | number,
    }
) {
    const color = overlayColorScheme.red.concat("98");
    const triangleSize = Number(fontSize) / 2; // By experimenting, this size looks good
    const createUpwardTriangle = (x: number, y: number) => {
        const tipX = x;
        const tipY = y + triangleSize;
        const baseX1 = x + triangleSize;
        const baseY1 = y;
        const baseX2 = x - triangleSize;
        const baseY2 = y;
        return `${tipX},${tipY} ${baseX1},${baseY1} ${baseX2},${baseY2}`;
    };

    // Function to calculate the angle between two points
    const calculateAngle = (x1: number, y1: number, x2: number, y2: number) => {
        return Math.atan2(y2 - y1, x2 - x1) * (180 / Math.PI);
    };


    const spaceBetweenLineAndTriangle = (angle: number) => {
        const offsetInPixels = Number(fontSize) * .8; // By experimenting, this size looks good
        let angleInRadians = (180 - angle) * (Math.PI / 180);
        let opposite = offsetInPixels * Math.sin(angleInRadians);
        let adjacent = offsetInPixels * Math.cos(angleInRadians);
        return { x: opposite, y: adjacent };
    }

    return (
        <>
            {lines && lines.map(e => {
                const angle = calculateAngle(e.start.x, e.start.y, e.end.x, e.end.y);
                const showArrows = e.label !== "Radial";
                const transformStart = `translate(${e.start.x + spaceBetweenLineAndTriangle(angle).x}, ${e.start.y + spaceBetweenLineAndTriangle(angle).y}) rotate(${angle})`;
                const transformEnd = `translate(${e.end.x + spaceBetweenLineAndTriangle(angle).x}, ${e.end.y + spaceBetweenLineAndTriangle(angle).y}) rotate(${angle})`;
                return (
                    <g key={`${e.start.x}-${e.start.y}-${e.end.x}-${e.end.y}`}>
                        <line
                            x1={e.start.x}
                            y1={e.start.y}
                            x2={e.end.x}
                            y2={e.end.y}
                            stroke={color.slice(0, -2)}
                            strokeWidth={strokeWidth}
                            strokeDasharray={4}
                        />
                        {showArrows && (
                            <>
                                <polygon
                                    points={createUpwardTriangle(0, 0)}
                                    fill={color.slice(0, -2)}
                                    transform={transformStart}
                                />
                                <polygon
                                    points={createUpwardTriangle(0, 0)}
                                    fill={color.slice(0, -2)}
                                    transform={transformEnd}
                                />
                            </>
                        )}
                    </g>
                );
            })}
            {lines && lines.map(e => (
                <text key={`${e.start.x}-${e.start.y}-text`}
                    x={e.start.x}
                    y={e.start.y - Number(strokeWidth) * 2}
                    fill={color.slice(0, -2)}
                    fontSize={fontSize}
                >
                    {e.label + " slice"}
                </text>
            ))}
        </>
    );
}
