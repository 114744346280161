import { CircularProgress, Fade } from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import { ImageLoadingStatus, SliceOrientation } from "../../types";

export default function SliceLoadingIndicator(
    { isLoading, percentDownloaded, status, orientation }: { isLoading: boolean, percentDownloaded: number, status: ImageLoadingStatus, orientation: SliceOrientation }
) {
    if (!isLoading) return null
    const loadingMessage = () => {
        switch (orientation) {
            case SliceOrientation.RADIAL:
                return "Loading Radial Slices"
            case SliceOrientation.AXIAL:
                return "Loading Axial Slices"
            case SliceOrientation.XY:
                return "Loading XY Slices"
            case SliceOrientation.XZ:
                return "Loading XZ Slices"
            case SliceOrientation.YZ:
                return "Loading YZ Slices"
            default:
                return "Loading Slices"
        }
    }

    const statusToMessage = (status: ImageLoadingStatus) => {
        switch (status) {
            case ImageLoadingStatus.SERVER_COMPILING_ZIP:
                return <Typography variant="body2" >
                    {"Checking subscription and compiling high-res images."}
                </Typography>
            case ImageLoadingStatus.DOWNLOADING_ZIP:
                return <>
                    <Typography variant="body2" >
                        {"Downloading images"}
                    </Typography>
                    <Typography variant="body2" >
                        {"Use a wired internet connection to load your scans faster!"}
                    </Typography>
                </>
            case ImageLoadingStatus.ERROR:
            default:
                return <Typography variant="body2" >
                    {"Something went wrong"}</Typography>
        }
    }

    return (
        <>
            <Grid container spacing={0} sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                mt: 4
            }} >
                <>
                    <Fade in={isLoading} >
                        <Typography variant="body1" >
                            {loadingMessage()}
                        </Typography>
                    </Fade>
                    <Fade in={isLoading} >
                        {statusToMessage(status)}
                    </Fade>
                </>
                {status === ImageLoadingStatus.DOWNLOADING_ZIP ?
                    <Typography variant="body1" >
                        {percentDownloaded} %
                    </Typography> :
                    null
                }
                <Fade in={isLoading} >
                    <CircularProgress
                        size={100}
                        sx={{ padding: 1 }} />
                </Fade>
            </Grid >
        </>
    );
}
