import useUrlState from "@ahooksjs/use-url-state";
import { useAuth0 } from "@auth0/auth0-react";
import Box from '@mui/material/Box';
import axios from "axios";
import { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import LeftNavigationDrawer from './components/LeftNavigationDrawer';
import PageSelection from './components/PageSelection';
import TopNavigationBar from './components/TopNavigationBar';
import EmailNotVerified from "./pages/EmailNotVerifiedPage";
import LoginPage from "./pages/LoginPage";
import SubscriptionLoadingPage from "./pages/SubscriptionLoadingPage";
import FaqPage from "./pages/faq/page";
import TosViewer from "./pages/terms_of_service/TosViewer";
import { MeasurementDimensions, PageOptions, ScanInfo, UserAccessState } from "./types";
import { ApiEndpoints, axiosConfigBase } from "./utils/apiUtils";
import { hotjarInit } from "./utils/hotjarInit";
import { MetricDisplayNames } from "./utils/inspection";



function App() {
  const drawerWidth = 52;
  const [urlState, setUrlState] = useUrlState<{ page: PageOptions, public?: boolean }>({ page: PageOptions.SCANS })
  const { isLoading, isAuthenticated, getIdTokenClaims, user } = useAuth0();
  const [userAccessState, setUserAccessState] = useState<UserAccessState>(UserAccessState.LOADING)
  const [scanList, setScanList] = useState<ScanInfo[]>([{} as ScanInfo])
  const [availableMetrics, setAvailableMetrics] = useState([] as MeasurementDimensions[])
  const [isDemoMode, setIsDemoMode] = useState<boolean>(true)

  useEffect(() => {
    if (isAuthenticated && !isLoading) {
      loadUserFromApi()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isLoading])

  const loadUserFromApi = async () => {
    const token = await getIdTokenClaims().then((claims) => claims ? claims.__raw : null);
    const isVerified = await getIdTokenClaims().then((claims) => claims ? claims.email_verified : false);
    if (!isVerified) {
      setUserAccessState(UserAccessState.EMAIL_UNVERIFIED)
      return
    }
    if (token) {
      const userStatus = await axios.get(ApiEndpoints.CHECK_USER, axiosConfigBase(token));
      const status = userStatus.data.user_access_status as UserAccessState
      switch (status) {
        case UserAccessState.SIGNUP_COMPLETE:
        case UserAccessState.GLIMPSE_INTERNAL_READ:
        case UserAccessState.GLIMPSE_INTERNAL_WRITE:
        case UserAccessState.CUSTOMER_OPERATOR:
          if (urlState.public === "true") {
            setIsDemoMode(true);
            setUrlState({ page: urlState.page, public: true })
            await initialDataLoader(true);
          }
          else if (urlState.public === "false") {
            setIsDemoMode(false);
            setUrlState({ page: urlState.page, public: false })
            await initialDataLoader(false);
          }
          // if no state provided in the link, assume private:
          else {
            setIsDemoMode(false);
            setUrlState({ page: urlState.page, public: false })
            await initialDataLoader(false);
          }
          break;
        case UserAccessState.SIGNUP_INCOMPLETE:
        default:
          setIsDemoMode(true)
          setUrlState({ page: urlState.page, public: true })
          await initialDataLoader(true)
          break;
      }
      setUserAccessState(status)
      if (user) { hotjarInit(user.email) }
    }
  }

  const handleDemoToggle = () => {
    initialDataLoader(!isDemoMode)
    setIsDemoMode(!isDemoMode);
    setUrlState({ page: urlState.page, public: !isDemoMode })
  }

  async function initialDataLoader(isDemoMode: boolean) {
    const token = await getIdTokenClaims().then((claims) => claims ? claims.__raw : null);
    if (token) {
      await loadScanListFromApi(token, isDemoMode).then(() => getAvailableMetrics(token))
    }
  }

  async function getAvailableMetrics(token: string) {
    // TODO: consolidate this loading logic, could move it down a layer too.
    try {
      const response = await axios.get(ApiEndpoints.INSPECTION_METRICS, axiosConfigBase(token));
      setAvailableMetrics(response.data.map((m: {
        metric_id: number
        metric_internal_name: string
        orientation: string
      }) => {
        const findMetric = MetricDisplayNames.find((e) => e.internal_name === m.metric_internal_name)
        return {
          metric_id: m.metric_id,
          metric_internal_name: m.metric_internal_name,
          orientation: m.orientation,
          metric_display_name: findMetric?.display_name,
          description: findMetric?.description,
        } as MeasurementDimensions
      }
      )
      );
    } catch (error: any) {
      console.log(error)
    }
  }

  const loadScanListFromApi = async (token: string, demoMode: boolean) => {
    let url = ApiEndpoints.SCAN_LIST
    if (demoMode) url = ApiEndpoints.DEMO_SCAN_LIST
    if (token) {
      const scan_response = await axios.get(url, axiosConfigBase(token));
      if (scan_response.data.length === 0) setScanList([] as ScanInfo[])
      if (scan_response.data.length > 0) setScanList((scan_response.data as ScanInfo[]).sort((a, b) => b.scan_id - a.scan_id));
    }
  }


  // TODO: probably gonna have to refactor this when we have more info on "demo" users
  const pageHandler = (isLoading: boolean, isAuthenticated: boolean, page: PageOptions) => {
    if (isLoading) return <SubscriptionLoadingPage />;
    if (isAuthenticated) {
      // TODO: maybe not the best location for this? but we need to scrub the Auth0 code and state
      // TODO: I think this is related to Peter's safari issues...
      const params = new URLSearchParams(window.location.search);
      params.delete("code");
      params.delete("state");
      window.history.replaceState({}, '', `${window.location.pathname}?${params}`);
    }
    if (page === PageOptions.FAQ) return <FaqPage />;
    if (page === PageOptions.TERMS) return <TosViewer />;
    if (!isLoading && !isAuthenticated) return <LoginPage />;
    if (!isLoading && isAuthenticated && userAccessState === UserAccessState.EMAIL_UNVERIFIED) return <EmailNotVerified />;
    if (!isLoading && isAuthenticated) {
      return <PageSelection
        selectedPage={page}
        isDemoMode={isDemoMode}
        scanList={scanList}
        userAccessState={userAccessState}
        availableMetrics={availableMetrics}
      />;
    }
  }
  return (
    <main >
      <TopNavigationBar
        isDemoMode={isDemoMode}
        handleDemoToggle={handleDemoToggle}
        userAccessState={userAccessState}
        selectedPage={urlState.page}
      />
      <LeftNavigationDrawer
        drawerWidth={drawerWidth}
        selectedPage={urlState.page}
        userAccessState={userAccessState}
        isDemoMode={isDemoMode}
      />
      <Box sx={{
        height: '100%',
        mt: '64px',
        ml: `${drawerWidth + 25}px`,
        mr: '25px',
      }}>
        {pageHandler(isLoading, isAuthenticated, urlState.page)}
      </Box>
    </main >
  )
}

// Wrap in router to enable using the url for state storage
let Name = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Name
