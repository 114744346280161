import { useAuth0 } from "@auth0/auth0-react";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import SomethingWentWrongPage from "../pages/SomethingWentWrongPage";

const UserProfile = () => {
    const { user } = useAuth0();
    if (!user) {
        return <SomethingWentWrongPage />;
    }
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Card sx={{
                m: 3,
            }} >
                <CardContent>
                    <img src={user.picture} alt={user.name} />
                    <Typography variant="h6" component="div" color='#FFF' sx={{ flexGrow: 1 }}>
                        {user.name}
                    </Typography>
                    <Typography variant="body1" component="div" color='#FFF' sx={{ flexGrow: 1 }}>
                        {user.email}
                    </Typography>
                </CardContent>
            </Card>
        </Box >
    );
};

export default UserProfile;
